import { ArrowsRightLeftIcon, PlusIcon, ArrowUpRightIcon } from "@heroicons/react/24/solid";

import { AppRoutes } from "../../../utils";
import { useGetWallets, walletsResourcePath } from "../../../services/queries/wallets";
import useFilters from "../../../hooks/useFilters";
import { Currency } from "../../../constants";
import WalletAction from "../WalletAction";
import { AnimatePresence } from "framer-motion";
import LoadingOverlay from "../LoadingOverlay";

interface WalletProps {
  currency: Currency;
  isVerifiedUser: boolean;
}

export function Wallet({ currency, isVerifiedUser }: WalletProps) {
  const { url } = useFilters(walletsResourcePath, { pageSize: "1", currency });

  const { data, isLoading } = useGetWallets(url.href);

  const walletsData = data?.data;
  const wallet = walletsData?.list[0] ?? null;
  const balance = wallet?.balance ?? 0;

  return (
    <div className="wallet">
      <AnimatePresence>
        {isLoading && (
          <LoadingOverlay message="Updating wallet balance..." />
        )}
      </AnimatePresence>
      <div className="mb-8">
        <p className="text-white/80 mb-1 text-center">Account Balance</p>
        <h1 className="text-4xl font-bold text-center mb-12">
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
            maximumFractionDigits: 0,
          }).format(balance)}
        </h1>
      </div>

      {/* Actions */}
      <div className="grid grid-cols-3 gap-4 bg-white p-4 rounded-3xl shadow-sm text-gray-900">
        <WalletAction
          to={AppRoutes.SWAP}
          icon={ArrowsRightLeftIcon}
          label="Swap"
          disabled={true}
          className="bg-green-500/20 text-green-500"
        />

        <WalletAction
          to={`${AppRoutes.DEPOSIT}?currency=${currency}`}
          icon={PlusIcon}
          label="Deposit"
          disabled={!isVerifiedUser || isLoading}
          className="bg-purple-500/20 text-purple-500"
        />
        <WalletAction
          to={AppRoutes.WITHDRAW}
          state={{ currency, balance, walletId: wallet?.id }}
          icon={ArrowUpRightIcon}
          label="Withdraw"
          disabled={!isVerifiedUser || isLoading}
          className="bg-blue-500/20 text-blue-500"
        />
      </div>
    </div>
  );
} 