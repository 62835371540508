import React from "react";
import cx from "classnames";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "neutral";
}

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <button
      {...props}
      className={cx(
        "flex text-sm py-2 px-10 rounded",
        className,
      )}
    >
      {children}
    </button>
  );
};

export default Button;
