import { apiClient } from "../../api";

const logo_url = import.meta.env.VITE_APP_LOGO_URL;
const policy_url = import.meta.env.VITE_APP_POLICY_URL;
const name = import.meta.env.VITE_APP_NAME;

const environment = import.meta.env.VITE_SMILE_ID_ENVIRONMENT;
const partner_id = import.meta.env.VITE_SMILE_ID_PARTNER_ID;
const theme_color = import.meta.env.VITE_SMILE_ID_THEME_COLOR;

declare const SmileIdentity: (config: {
  token: string;
  product: string;
  document_ids?: Array<string>;
  callback_url: string;
  environment: "sandbox" | "live";
  partner_details: {
    partner_id: string;
    name: string;
    logo_url: string;
    policy_url: string;
    theme_color?: string;
  };
  onSuccess?: () => void;
  onClose?: () => void;
  onError?: (error: {
    message: string;
    data?: Record<string, unknown>;
  }) => void;
}) => void;

type IdentityToken = {
 token: string;
callbackUrl: string;
}

export const getWebToken = async (
  product: "biometric_kyc" | "smartselfie" | "doc_verification"
): Promise<IdentityToken | undefined> => {
  const url = `/identity/token?product=${product}`;

  try {
    const {data }= await apiClient.get({ url });

    return data; 
  } catch (error) {
    console.error("Error fetching web token:", error);
  }
};

export const configureSmileIdentityWebIntegration = (
  {token, callbackUrl:callback_url}: IdentityToken,
  product: "biometric_kyc" | "smartselfie" | "doc_verification",
  callbacks: {
    onSuccess: () => void;
    onClose: () => void;
    onError: (error: { message: string; data?: Record<string, unknown> }) => void;
  }
) => {
  SmileIdentity({
    token,
    product, 
    callback_url,
    environment,
    partner_details: {
      partner_id,
      name,
      logo_url,
      policy_url,
      theme_color,
    },
    onSuccess: callbacks.onSuccess,
    onClose: callbacks.onClose,
    onError: callbacks.onError,
});
};
