import { motion } from "framer-motion";
import { Link } from "@tanstack/react-router";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import PageTransition from "../PageTransition";
import { AppRoutes } from "../../utils";

interface Props {
  title?: string;
  description?: string;
  showBackLink?: boolean;
}

const ComingSoon = ({ 
  title = "Coming Soon!", 
  description = "We're working hard to bring you something amazing. Stay tuned!", 
  showBackLink = true 
}: Props) => {
  return (
    <PageTransition>
      {showBackLink && (
          <Link
            to={AppRoutes.DASHBOARD}
            className="flex items-center px-4 py-4 text-gray-600 hover:text-gray-800"
          >
            <ArrowLeftIcon className="h-5 w-5 mr-1" />
            Back to Dashboard
          </Link>
        )}
      <div className="flex flex-col items-center justify-center min-h-[80vh] px-4 text-center">
        
        
        <motion.div
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", stiffness: 200, damping: 20 }}
          className="mb-6"
        >
          <img 
            src="/coming-soon.svg" 
            alt="Coming Soon" 
            className="w-64 h-64 mx-auto"
          />
        </motion.div>

        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="text-2xl font-bold mb-4"
        >
          {title}
        </motion.h1>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="text-gray-600 max-w-md"
        >
          {description}
        </motion.p>
      </div>
    </PageTransition>
  );
};

export default ComingSoon; 