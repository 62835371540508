import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

function useDebounce<T>(value: T, delay: number = 500): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const debouncedFn = debounce((newValue: T) => {
      setDebouncedValue(newValue);
    }, delay);

    debouncedFn(value);

    return () => {
      debouncedFn.cancel();
    };
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce; 