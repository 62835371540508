import { useQuery, type UseQueryResult } from "@tanstack/react-query";

import { apiClient } from "../../api";
import { type RATES_API_RESPONSE } from "./types";
import keys from "./keys";
import { baseURL } from "../../../utils";

interface RateReturnType {
  isLoading: boolean;
  data: RATES_API_RESPONSE | null;
  isSuccess: boolean;
  error: unknown;
}

export const ratesResourcePath = "rates";

const useGetRates = (
  url: string,
  pageNumber: string,
  pageSize: string,
): RateReturnType => {
  const hash = [keys.read, `${pageNumber}-${pageSize}`];
  const {
    isLoading,
    data = null,
    isSuccess,
    error,
  }: UseQueryResult<RATES_API_RESPONSE | null, unknown> = useQuery(
    hash,
    async () =>
      await apiClient.get({
        url,
      }),
  );
  return { isLoading, data, isSuccess, error };
};

const useGetRate = (rateId: string): RateReturnType => {
  const hash = [keys.read];
  const {
    isLoading,
    data = null,
    isSuccess,
    error,
  }: UseQueryResult<RATES_API_RESPONSE | null, unknown> = useQuery(
    hash,
    async () =>
      await apiClient.get({ url: `${baseURL}/${ratesResourcePath}/${rateId}` }),
  );
  return { isLoading, data, isSuccess, error };
};

export { useGetRates, useGetRate };
