import { useEffect } from "react";
import { createFileRoute, useNavigate, useRouter } from "@tanstack/react-router";
import Select, { SingleValue } from "react-select";
import { GoArrowLeft } from "react-icons/go";
import { FaSpinner } from "react-icons/fa6";

import { SET_TRANSACTION_FORM_DATA, useTransaction } from "../../../contexts/TransactionContext";
import { useGetPaymentProviders, useResolveAccountNumber } from "../../../services/queries/payments";
import PageTransition from "../../../components/PageTransition";
import { currencyDetails, PaymentMethod, WithdrawalType } from "../../../constants";
import { useCreateWithdrawal } from "../../../services/queries/wallets";

export const Route = createFileRoute("/_app/withdraw/beneficiary")({
  component: Beneficiary,
});

function Beneficiary() {
  const { history } = useRouter();
  const navigate = useNavigate();

  const { state: formData, dispatch } = useTransaction();
  const { accountName, accountNumber, bankCode, paymentMethod, withdrawalType, baseCurrency, quoteCurrency, saveBeneficiary } = formData;

  const currency = withdrawalType === WithdrawalType.INTERNATIONAL ? quoteCurrency : baseCurrency;
  const countryCode = currencyDetails.find(item => item.currency === currency)?.countryCode;
  const { data: paymentProvidersData, isLoading: isLoadingPaymentProviders } = useGetPaymentProviders(countryCode, paymentMethod);
  const paymentProviderList = paymentProvidersData?.data;
  const paymentProviders = paymentProviderList ? paymentProviderList.map(provider => ({
    value: provider.code,
    label: provider.name,
  })) : [];
  const selectedPaymentProvider = paymentProviders?.find(provider => provider.value === bankCode);

  const { data: resolvedAccountNumberData, isLoading: isResolvingAccountNumber } = useResolveAccountNumber(accountNumber, bankCode, countryCode);
  const resolvedAccountName = resolvedAccountNumberData?.data;

  const { mutate: initiateWithdrawal, isLoading: isSubmittingWithdrawalForm } = useCreateWithdrawal();

  const handlePaymentMethodChange = (method: SingleValue<{ value: string; label: string }>) => {
    dispatch({
      type: SET_TRANSACTION_FORM_DATA,
      payload: {
        ...formData,
        paymentMethod: method?.value as PaymentMethod,
      },
    });
  };

  const handleAccountNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Only allow digits and limit to 11 characters
    const value = e.target.value.replace(/\D/g, '').slice(0, 11);

    dispatch({
      type: SET_TRANSACTION_FORM_DATA,
      payload: {
        ...formData,
        accountNumber: value,
        accountName: "",
      },
    });
  };

  const handlePaymentProviderChange = (provider: SingleValue<{ value: string; label: string }>) => {
    dispatch({
      type: SET_TRANSACTION_FORM_DATA,
      payload: {
        ...formData,
        bankCode: provider?.value,
        accountName: "",
      },
    });
  };

  const handleSaveBeneficiaryToggle = () => {
    dispatch({
      type: SET_TRANSACTION_FORM_DATA,
      payload: {
        ...formData,
        saveBeneficiary: !saveBeneficiary,
      },
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    navigate({ to: "/withdraw/review" });
  };

  const paymentMethods = currencyDetails.find(item => item.currency === currency)?.paymentMethods ?? [];
  const selectedPaymentMethod = paymentMethods.find(method => method.value === paymentMethod);

  const isInValidBeneficiaryForm = !accountNumber || !accountName || !bankCode || !selectedPaymentMethod;

  useEffect(() => {
    if (!paymentMethods[0].value) {
      return;
    }

    dispatch({
      type: SET_TRANSACTION_FORM_DATA,
      payload: {
        ...formData,
        paymentMethod: paymentMethods[0].value,
      },
    });
  }, [paymentMethods, currency]);

  useEffect(() => {
    if (!resolvedAccountName) {
      return;
    }

    // get bank name
    const bankName = paymentProviderList?.find(provider => provider.code === bankCode)?.name;

    dispatch({
      type: SET_TRANSACTION_FORM_DATA,
      payload: {
        ...formData,
        accountName: resolvedAccountName,
        bankName,
      },
    });
  }, [resolvedAccountName]);

  return (
    <PageTransition>
      <div className="max-w-xl mx-auto">
        <div className="p-8">
          <div className="relative py-4">
            <GoArrowLeft
              size={24}
              className="absolute cursor-pointer top-1/2 -translate-y-1/2"
              onClick={() => history.go(-1)}
            />
            <h1 className="text-2xl font-semibold text-center">
              Enter beneficiary details
            </h1>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="beneficiary-form-field">
              <label className="block text-gray-700 mb-2" htmlFor="paymentMethod">Payment Method</label>
              <Select
                classNamePrefix="select"
                inputId="paymentMethod"
                value={selectedPaymentMethod}
                options={paymentMethods}
                onChange={handlePaymentMethodChange}
              />
            </div>

            <div className="beneficiary-form-field">
              <label className="block text-gray-700 mb-2" htmlFor="bankCode">
                {paymentMethod === PaymentMethod.BANK ? "Bank" : "Provider"}
              </label>
              <Select
                classNamePrefix="select"
                isClearable
                isSearchable
                inputId="bankCode"
                value={selectedPaymentProvider}
                options={paymentProviders}
                onChange={handlePaymentProviderChange}
              />
            </div>

            <div className="beneficiary-form-field">
              <label className="block text-gray-700 mb-2" htmlFor="accountNumber">Account number</label>
              <input
                type="text"
                id="accountNumber"
                name="accountNumber"
                value={accountNumber}
                onChange={handleAccountNumberChange}
                maxLength={10}
                pattern="\d{10}"
                placeholder="Enter 10 digit account number"
                className="w-full p-3 border rounded-lg"
              />

              {accountNumber && accountNumber.length !== 10 && (
                <p className="text-red-500 text-sm mt-1">Account number must be 10 digits</p>
              )}
            </div>

            <div className="beneficiary-form-field">
              <label className="block text-gray-700 mb-2" htmlFor="accountName">Account name</label>
              <input
                type="text"
                id="accountName"
                name="accountName"
                value={accountName}
                disabled
                className="w-full p-3 border rounded-lg"
              />

              {isResolvingAccountNumber && (
                <div className="flex items-center gap-2 mt-1 text-sm text-gray-600">
                  <FaSpinner className="animate-spin" />
                  <span>Resolving account number...</span>
                </div>
              )}
            </div>


            <div className="beneficiary-form-field flex items-center">
              <label className="flex items-center cursor-pointer">
                <div className="relative">
                  <input
                    id="saveBeneficiary"
                    name="saveBeneficiary"
                    type="checkbox"
                    className="sr-only"
                    checked={saveBeneficiary}
                    onChange={handleSaveBeneficiaryToggle}
                  />
                  <div
                    className={`w-14 h-8 rounded-full transition-colors ${formData.saveBeneficiary ? "bg-green-500" : "bg-gray-300"
                      }`}
                  >
                    <div
                      className={`absolute w-6 h-6 rounded-full bg-white top-1 transition-transform ${saveBeneficiary
                        ? "translate-x-7"
                        : "translate-x-1"
                        }`}
                    />
                  </div>
                </div>
                <span className="ml-3">Save beneficiary</span>
              </label>
            </div>

            <button
              type="submit"
              disabled={isInValidBeneficiaryForm}
              className="w-full p-4 bg-blue-600 text-white rounded-lg mt-6 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmittingWithdrawalForm ? (
                <FaSpinner className="animate-spin inline" />
              ) : (
                "Continue"
              )}
            </button>
          </form>
        </div>
      </div>
    </PageTransition>
  );
}
