import { motion } from "framer-motion";
import { FaSpinner } from "react-icons/fa6";

interface Props {
  message?: string;
}

const LoadingOverlay = ({ message = "Updating your wallet..." }: Props) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50"
    >
      <div className="bg-white p-6 rounded-xl shadow-lg max-w-sm w-full mx-4">
        <div className="flex flex-col items-center text-center">
          <FaSpinner className="w-8 h-8 text-blue-600 animate-spin mb-4" />
          <p className="text-gray-700 font-medium">{message}</p>
        </div>
      </div>
    </motion.div>
  );
};

export default LoadingOverlay; 