import {
  useQuery,
  type UseQueryResult,
} from "@tanstack/react-query";

import { apiClient } from "../../api";
import {type GET_VIRTUAL_ACCOUNTS_API_RESPONSE } from "./types";
import keys from "./keys";

interface VirtualAccountReturnType {
  isLoading: boolean;
  data: GET_VIRTUAL_ACCOUNTS_API_RESPONSE | null;
  isSuccess: boolean;
  error: unknown;
}

export const virtualAccountsResourcePath = "virtual-accounts/user";

const useGetVirtualAccounts = (
  url: string,
): VirtualAccountReturnType => {
  const hash = [keys.read, `${url}`];
  const {
    isLoading,
    data = null,
    isSuccess,
    error,
  }: UseQueryResult<GET_VIRTUAL_ACCOUNTS_API_RESPONSE | null, unknown> = useQuery(
    hash,
    async () => await apiClient.get({ url }),
  );
  return { isLoading, data, isSuccess, error };
};

export { useGetVirtualAccounts };
