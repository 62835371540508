import { useState } from "react";
import { createFileRoute, Link } from "@tanstack/react-router";
import { SubmitHandler, useForm } from "react-hook-form";
import { sendPasswordResetEmail } from "firebase/auth";

import { auth } from "../../utils/firebase";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import { showToast } from "../../utils/toast";
import { logError } from "../../utils/sentry";
import PageTransition from "../../components/PageTransition";

interface FormProps {
  email: string;
}

export const Route = createFileRoute("/_auth/forgotPassword")({
  component: ForgotPassword,
});

function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<FormProps> = async ({ email }) => {
    try {
      setIsLoading(true);
      await sendPasswordResetEmail(auth, email);
      showToast(
        "Password reset successfully. Check your email for instructions.",
      );
      setIsLoading(false);
    } catch (error: unknown) {
      setIsLoading(false);
      logError(error as Error);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProps>({ mode: "onSubmit", reValidateMode: "onChange" });

  return (
    <PageTransition>
      <div className="forgot-password-page">
        <h4 className="text-xl font-bold mb-3">Forgot Password?</h4>
        <p className="mb-10">
          To reset your password, enter the email address you used when you signed
          up.
        </p>

        <div className="form ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-control mb-8">
              <label className="block w-100 uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Email
              </label>
              <TextInput
                placeholder="Enter your email address"
                className="w-full py-4 px-2 rounded-lg border border-gray-200"
                {...register("email", { required: "Email Address is required " })}
                error={errors.email}
              />
            </div>

            <Button
              className="bg-primary hover:bg-accent transition-colors font-bold text-white w-full py-3 ml-1 hover:border-accent rounded-lg border-primary"
              type="submit"
              disabled={isLoading}
            >
              Reset Password
            </Button>

            <div className="text-black text-center mt-5">
              <Link to="/login" className="text-primary">
                ← Back to Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </PageTransition>
  );
}
