import { createFileRoute, useRouter } from "@tanstack/react-router";
import { GoArrowLeft } from "react-icons/go";
import { FiDownload } from "react-icons/fi";

import Spacer from "../../../components/Spacer";
import { StatusPill } from "../../../components/transactions/StatusPill";
import { generateReceipt } from "../../../components/transactions/TransactionReceipt";
import {
  useGetTransaction,
} from "../../../services/queries/transactions";
import { formatDateTime } from "../../../utils/transaction";
import { useAppState } from "../../../contexts/AppContext";
import PageTransition from "../../../components/PageTransition";

export const Route = createFileRoute("/_app/transactions/$id")({
  component: TransactionPage,
});

function TransactionPage() {
  const {
    state: { user },
  } = useAppState();

  const { history } = useRouter();
  const { id } = Route.useParams<{ id: string }>();

  const { data: transactionData, isLoading } = useGetTransaction(id);
  const transaction = transactionData?.data;

  if (isLoading) {
    return (
      <PageTransition>
        <div>Loading...</div>
      </PageTransition>
    );
  }

  if (!transaction) {
    return (
      <PageTransition>
        <div>Transaction not found</div>
      </PageTransition>
    );
  }

  return (
    <PageTransition>
      <div className="p-8">
        <div className="relative">
          <GoArrowLeft
            size={24}
            className="absolute cursor-pointer"
            onClick={() => history.go(-1)}
          />
          <p className="font-bold text-xl text-blue-600 text-center">
            Transaction Details
          </p>
        </div>
        <Spacer size="lg" />
        <div className="flex justify-between items-center">
          <StatusPill status={transaction.status} />
          <div
            className="flex text-sm font-semibold gap-1 items-center cursor-pointer"
            onClick={() =>
              generateReceipt(
                transaction,
                `${user?.firstName} ${user?.lastName}`,
              )
            }
          >
            Receipt
            <FiDownload />
          </div>
        </div>
        <Spacer size="lg" />
        <div className="border rounded-md p-4">
          <div className="flex justify-between">
            <p className="text-sm">You sent</p>
            <p className="text-sm">Received</p>
          </div>
          <div className="flex justify-between">
            <p className="text-md font-bold">
              {transaction?.amount.toLocaleString("en-US")}{" "}
              {transaction?.currency}
            </p>
            {/* <p className="text-md font-bold">
              {transaction?.quoteAmount.toLocaleString("en-US")}{" "}
              {transaction?.quoteCurrency}
            </p> */}
          </div>
        </div>
        <Spacer size="lg" />
        <div className="flex justify-between">
          <p className="text-sm">Account holder</p>
          <p className="text-sm">{transaction?.beneficiary.accountName}</p>
        </div>
        <Spacer size="md" />
        <div className="flex justify-between">
          <p className="text-sm">Account number</p>
          <p className="text-sm">{transaction?.beneficiary.accountNumber}</p>
        </div>
        <Spacer size="md" />
        <div className="flex justify-between">
          <p className="text-sm">Institution</p>
          <p className="text-sm">{transaction?.beneficiary.institution}</p>
        </div>
        <Spacer size="md" />
        <div className="flex justify-between">
          <p className="text-sm">Payment Method</p>
          <p className="text-sm">{transaction?.beneficiary.paymentMethod}</p>
        </div>
        <Spacer size="md" />
        <div className="border-b" />
        <Spacer size="md" />
        <div className="flex justify-between">
          <p className="text-sm">Exchange rate</p>
          {/* <p className="text-sm">1 {transaction.currency} = {transaction.rate.finalRate} {transaction.quoteCurrency}</p> */}
        </div>
        <Spacer size="md" />
        <div className="border-b" />
        <Spacer size="md" />
        <div className="flex justify-between">
          <p className="text-sm">Time</p>
          <p className="text-sm">
            {formatDateTime(transaction?.created)}
          </p>
        </div>
        <Spacer size="md" />
        <div className="flex justify-between">
          <p className="text-sm">Reference</p>
          <p className="text-sm">{transaction?.reference}</p>
        </div>
      </div>
    </PageTransition>
  );
} 