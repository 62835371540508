import { configureSentry } from "./utils/sentry";
import React from "react";
import ReactDOM from "react-dom/client";
import { registerSW } from "virtual:pwa-register";
import { ToastContainer } from "react-toastify";

import AppProvider from "./contexts/AppContext";
import TransactionProvider from "./contexts/TransactionContext";
import ReactQueryProvider from "./providers/ReactQueryProvider";
import App from "./App";
import "./index.css";

configureSentry();

const updateSW = registerSW({
  onOfflineReady() {},
  //for registerType: 'prompt'
  onNeedRefresh() {
    if (confirm("New content available. Reload?")) {
      updateSW(true);
    }
  },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ReactQueryProvider>
      <ToastContainer />
      <AppProvider>
        <TransactionProvider>
          <App />
        </TransactionProvider>
      </AppProvider>
    </ReactQueryProvider>
  </React.StrictMode>,
);
