import { useQuery, type UseQueryResult } from "@tanstack/react-query";

import { baseURL } from "../../../utils";
import { apiClient } from "../../api";
import { GET_PAYMENT_PROVIDERS_API_RESPONSE, GET_RESOLVE_ACCOUNT_NUMBER_API_RESPONSE } from "./types";
import keys from "./keys";
import { PaymentMethod } from "../../../constants";

interface GetPaymentProvidersReturnType {
  isLoading: boolean;
  data: GET_PAYMENT_PROVIDERS_API_RESPONSE | null;
  error: unknown;
}

interface ResolveAccountNumberReturnType {
  isLoading: boolean;
  data: GET_RESOLVE_ACCOUNT_NUMBER_API_RESPONSE | null;
  error: unknown;
}

export const paymentProvidersResourcePath = "payments/providers";

const useGetPaymentProviders = (countryCode: string | undefined, paymentMethod: PaymentMethod | undefined): GetPaymentProvidersReturnType => {
  const enabled = !!countryCode && !!paymentMethod;

  const url = `${baseURL}/${paymentProvidersResourcePath}/country/${countryCode}/${paymentMethod}`;
  const hash = [keys.read, url];

  const { isInitialLoading: isLoading, data = null, error }: UseQueryResult<GET_PAYMENT_PROVIDERS_API_RESPONSE | null, unknown> = useQuery(
    hash,
    async () => await apiClient.get({ url }),
    {
      enabled,
    }
  );

  return { isLoading, data, error };
};

const useResolveAccountNumber = (accountNumber: string, bankCode: string | undefined, countryCode: string | undefined): ResolveAccountNumberReturnType => {
  const enabled = !!accountNumber && accountNumber.length === 10 && !!bankCode && bankCode.length > 0 && !!countryCode && countryCode.length > 0;

  const url = `${baseURL}/${paymentProvidersResourcePath}/resolveAccountNumber?accountNumber=${accountNumber}&bankCode=${bankCode}&countryCode=${countryCode}`;
  const hash = [keys.read, url];
  const { isInitialLoading: isLoading, data = null, error }: UseQueryResult<GET_RESOLVE_ACCOUNT_NUMBER_API_RESPONSE | null, unknown> = useQuery(
    hash,
    async () => await apiClient.get({ url }),
    {
      enabled,
    }
  );
  return { isLoading, data, error };
};

export { useGetPaymentProviders, useResolveAccountNumber };
