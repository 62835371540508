import { useEffect, useState } from "react";
import { Currency, CurrencyItem } from "../../../constants";
import TransferInput from "../TransferInput";

interface WithdrawalFormProps {
  isInternational: boolean;
  baseAmount: string;
  quoteAmount: string;
  baseCurrency: Currency;
  quoteCurrency: Currency;
  isLoadingRates: boolean;
  exchangeRate?: number;
  balance: number;
  baseCurrencies: CurrencyItem[];
  quoteCurrencies: CurrencyItem[];
  remarks: string;
  withdrawalFee?: number;
  isLoadingFee: boolean;
  onBaseAmountChange: (amount: string) => void;
  onQuoteAmountChange: (amount: string) => void;
  onBaseCurrencyChange: (currency: CurrencyItem) => void;
  onQuoteCurrencyChange: (currency: CurrencyItem) => void;
  onRemarksChange: (remarks: string) => void;
  onSubmit: () => void;
}

export function WithdrawalForm({
  isInternational,
  baseAmount,
  quoteAmount,
  baseCurrency,
  quoteCurrency,
  isLoadingRates,
  exchangeRate,
  balance,
  baseCurrencies,
  quoteCurrencies,
  remarks,
  withdrawalFee,
  isLoadingFee,
  onBaseAmountChange,
  onQuoteAmountChange,
  onBaseCurrencyChange,
  onQuoteCurrencyChange,
  onRemarksChange,
  onSubmit,
}: WithdrawalFormProps) {
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const numericBaseAmount = parseFloat(baseAmount);
    const numericFee = withdrawalFee || 0;

    if (numericBaseAmount + numericFee > balance) {
      setError("Amount plus fee exceeds your balance.");
    } else {
      setError("");
    }
  }, [baseAmount, withdrawalFee, balance]);

  const isInValidWithdrawalForm =
    !baseAmount ||
    (isInternational && !quoteAmount) ||
    isLoadingRates ||
    isLoadingFee ||
    withdrawalFee === undefined ||
    error !== "";

  return (
    <div className="space-y-5">
      {/* Amount Input Section */}
      <TransferInput
        label="You send"
        balance={balance}
        amount={baseAmount}
        onAmountChange={onBaseAmountChange}
        currencies={isInternational ? baseCurrencies : []}
        selectedCurrency={baseCurrencies.find(c => c.value === baseCurrency)}
        onCurrencySelected={onBaseCurrencyChange}
        showCurrencySelect={isInternational}
      />

      {/* Error Message */}
      {error && (
        <div className="text-red-500 text-sm font-bold">
          {error}
        </div>
      )}

      {/* Exchange Rate Info - Only show for international transfers */}
      {isInternational && exchangeRate && (
        <>
          <div className="flex items-center justify-center gap-3 text-gray-500">
            {isLoadingRates ? (
              <>
                <div className="animate-spin h-4 w-4 border-2 border-gray-500 border-t-transparent rounded-full" />
                <span>Loading exchange rate...</span>
              </>
            ) : exchangeRate && (
              <span>
                1 {baseCurrency} = {exchangeRate} {quoteCurrency}
              </span>
            )}
          </div>

          {/* Beneficiary Gets Section */}
          <TransferInput
            label="Beneficiary gets"
            amount={quoteAmount}
            onAmountChange={onQuoteAmountChange}
            currencies={quoteCurrencies}
            selectedCurrency={quoteCurrencies.find(c => c.value === quoteCurrency)}
            onCurrencySelected={onQuoteCurrencyChange}
          />
        </>
      )}

      {/* Withdrawal Fees */}
      <div className="bg-gray-50 rounded-lg p-4">
        <div className="flex justify-between items-center text-sm">
          <span className="text-gray-600">Withdrawal Fee</span>
          <div className="flex gap-2">
            {isLoadingFee ? (
              <>
                <div className="animate-spin h-4 w-4 border-2 border-gray-500 border-t-transparent rounded-full" />
                <span>Loading fee...</span>
              </>
            ) : (
              <span className="font-medium">
                {withdrawalFee !== undefined ? `${withdrawalFee} ${isInternational ? quoteCurrency : baseCurrency}` : ''}
              </span>
            )}
          </div>
        </div>
      </div>

      {/* Remarks Input */}
      <div className="bg-white rounded-lg p-4 relative border border-gray-200">
        <input
          type="text"
          placeholder="Add Note"
          value={remarks}
          onChange={(e) => onRemarksChange(e.target.value)}
          className="w-full focus:outline-none text-gray-500"
          maxLength={18}
        />
        <div className="text-right text-xs text-gray-400 absolute bottom-2 right-2">
          {remarks.length}/18
        </div>
      </div>

      {/* Submit Button */}
      <button
        disabled={isInValidWithdrawalForm}
        className={`w-full bg-blue-600 text-white py-4 px-6 rounded-full font-medium ${isInValidWithdrawalForm
          ? "opacity-50 cursor-not-allowed"
          : "hover:bg-blue-700"
          }`}
        onClick={onSubmit}
      >
        Continue
      </button>
    </div>
  );
}
