import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { GoArrowLeft } from "react-icons/go";
import { FaSpinner } from "react-icons/fa6";

import { RESET_TRANSACTION_FORM_DATA, useTransaction } from "../../../contexts/TransactionContext";
import PageTransition from "../../../components/PageTransition";
import { PaymentMethod, WithdrawalType } from "../../../constants";
import { useCreateWithdrawal } from "../../../services/queries/wallets";
import { showToast } from "../../../utils/toast";
import { buildWithdrawalRequest } from "../../../utils/wallet";

export const Route = createFileRoute("/_app/withdraw/review")({
  component: ReviewPage,
});

function ReviewPage() {
  const navigate = useNavigate();
  const { state: formData, dispatch } = useTransaction();
  const {
    withdrawalType,
    withdrawalFee,
    baseAmount,
    quoteAmount,
    baseCurrency,
    quoteCurrency,
    accountName,
    accountNumber,
    bankName,
    remarks,
    walletId,
    paymentMethod,
  } = formData;

  const { mutate: initiateWithdrawal, isLoading } = useCreateWithdrawal();

  const isInternational = withdrawalType === WithdrawalType.INTERNATIONAL;
  const amount = isInternational ? quoteAmount : baseAmount;
  const currency = isInternational ? quoteCurrency : baseCurrency;

  const handleSubmit = () => {
    if (!walletId) {
      showToast("Invalid wallet selected", "error");
      navigate({ to: "/" });
      return;
    }

    const request = buildWithdrawalRequest(formData);

    initiateWithdrawal(
      { data: request, walletId },
      {
        onSuccess: () => {
          dispatch({
            type: RESET_TRANSACTION_FORM_DATA,
          });

          navigate({ to: "/withdraw/success" });
        },
        onError: (error) => {
          showToast(
            "An error occurred while processing your withdrawal. Please try again.",
            "error"
          );
        },
      }
    );
  };

  const totalAmount = Number(amount) + (Number(withdrawalFee) ?? 0);

  return (
    <PageTransition>
      <div className="max-w-xl mx-auto">
        <div className="p-8">
          <div className="relative mb-6">
            <Link
              to="/withdraw/beneficiary"
              className="absolute top-1/2 -translate-y-1/2"
            >
              <GoArrowLeft size={24} className="cursor-pointer" />
            </Link>
            <h1 className="text-xl font-bold text-center">Review Transaction</h1>
          </div>

          {/* Transaction Details */}
          <div className="bg-white rounded-xl p-6 shadow-sm mb-6">
            <h2 className="text-lg font-semibold mb-4">Transaction Details</h2>

            <div className="space-y-4">
              {isInternational && (
                <>
                  <div className="flex justify-between">
                    <span className="text-gray-600">You're sending</span>
                    <span className="font-medium">
                      {baseAmount} {baseCurrency}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">They'll receive</span>
                    <span className="font-medium">
                      {quoteAmount} {quoteCurrency}
                    </span>
                  </div>
                </>
              )}

              {!isInternational && (
                <div className="flex justify-between">
                  <span className="text-gray-600">Amount</span>
                  <span className="font-medium">
                    {amount} {currency}
                  </span>
                </div>
              )}

              {withdrawalFee && (
                <div className="flex justify-between">
                  <span className="text-gray-600">Withdrawal Fee</span>
                  <span className="font-medium">
                    {withdrawalFee} {isInternational ? quoteCurrency : baseCurrency}
                  </span>
                </div>
              )}

              <div className="flex justify-between">
                <span className="text-gray-600">Total Amount</span>
                <span className="font-medium">
                  {totalAmount} {currency}
                </span>
              </div>

              {remarks && (
                <div className="flex justify-between">
                  <span className="text-gray-600">Description</span>
                  <span className="font-medium">{remarks}</span>
                </div>
              )}
            </div>
          </div>

          {/* Beneficiary Details */}
          <div className="bg-white rounded-xl p-6 shadow-sm mb-8">
            <h2 className="text-lg font-semibold mb-4">Beneficiary Details</h2>

            <div className="space-y-4">
              <div className="flex justify-between">
                <span className="text-gray-600">Account Name</span>
                <span className="font-medium">{accountName}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Account Number</span>
                <span className="font-medium">{accountNumber}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">
                  {paymentMethod === PaymentMethod.BANK ? "Bank" : "Provider"}
                </span>
                <span className="font-medium">{bankName}</span>
              </div>
            </div>
          </div>

          {/* Warning Notice */}
          <div className="bg-yellow-50 border border-yellow-200 rounded-xl p-4 mb-6">
            <div className="flex items-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-yellow-600 mt-0.5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
              <div>
                <h3 className="text-sm font-medium text-yellow-800">
                  Important Notice
                </h3>
                <p className="mt-1 text-sm text-yellow-700">
                  Please review all details carefully. Transactions cannot be reversed once confirmed.
                </p>
              </div>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="space-y-4 mb-10">
            <button
              onClick={handleSubmit}
              disabled={isLoading}
              className="w-full bg-blue-600 text-white py-4 rounded-lg font-medium disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? (
                <FaSpinner className="animate-spin inline" />
              ) : (
                "Confirm Withdrawal"
              )}
            </button>

            <Link
              to="/withdraw/beneficiary"
              className="block w-full text-center py-4 text-gray-600 font-medium"
            >
              Go Back
            </Link>
          </div>
        </div>
      </div>
    </PageTransition>
  );
}