import { ArrowsRightLeftIcon } from "@heroicons/react/24/solid";
import { Link } from "@tanstack/react-router";

interface WalletActionProps {
  to: string;
  state?: Record<string, string | number | undefined>;
  icon: typeof ArrowsRightLeftIcon;
  label: string;
  disabled?: boolean;
  className?: string;
  currency?: string;
  balance?: number;
}

function WalletAction({
  to,
  icon: Icon,
  label,
  disabled,
  state,
  className
}: WalletActionProps) {
  const baseClasses = "flex flex-col items-center transition-opacity duration-200";
  const classes = disabled ? `${baseClasses} opacity-50 cursor-not-allowed` : baseClasses;

  if (disabled) {
    return (
      <div className={classes}>
        <div className="bg-gray-500/10 p-3 rounded-full mb-2">
          <Icon className="w-6 h-6 text-gray-500" />
        </div>
        <span className="text-sm">{label}</span>
      </div>
    );
  }

  return (
    <Link to={to} state={state} className={classes}>
      <div className={`p-3 rounded-full mb-2 ${className}`}>
        <Icon className="w-6 h-6 text-primary" />
      </div>
      <span className="text-sm">{label}</span>
    </Link>
  );
}

export default WalletAction;