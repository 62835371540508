import { User } from "../../../services/queries/user/types";

interface AccountHeaderProps {
  user: User | null
}

const AccountHeader = ({ user }: AccountHeaderProps) => {
  const initials = `${user?.firstName?.[0] || ""}${user?.lastName?.[0] || ""}`;

  return (
    <div className="bg-white p-6 flex flex-col items-center justify-center space-y-3">
      {/* Avatar */}
      <div className="w-16 h-16 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 font-semibold text-xl">
        {initials}
      </div>

      {/* User Info */}
      <div className="text-center">
        <h2 className="text-lg font-semibold text-gray-900">
          {user?.firstName} {user?.lastName}
        </h2>
        <p className="text-sm text-gray-500">{user?.email}</p>
      </div>

      {/* Verification Badge */}
      <div className={`text-sm px-3 py-1 rounded-full ${user?.hasVerifiedID ? 'bg-green-100 text-green-700' : 'bg-yellow-100 text-yellow-700'}`}>
        {user?.hasVerifiedID ? 'Verified Account' : 'Unverified Account'}
      </div>
    </div>
  );
};

export default AccountHeader; 