import { jsPDF } from "jspdf";
import { Transaction } from "../../../services/queries/transactions/types";
import { formatDateTime } from "../../../utils/transaction";

function formatAmount(amount: number | undefined): string {
  if (amount === undefined || amount === null) return "0.00";

  return amount.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function safeText(text: string | undefined | null): string {
  return text || "";
}

export function generateReceipt(transaction: Transaction, sender: string) {
  const doc = new jsPDF({
    unit: "mm",
    format: "a4",
  });

  const pageWidth = doc.internal.pageSize.width;
  const margin = 20;
  const contentWidth = pageWidth - margin * 2;

  const logoHeight = 10;
  const logoAspectRatio = 6.8; // Assuming the original logo has a width:height ratio of 3.75:1
  const logoWidth = logoHeight * logoAspectRatio;
  const logoX = (pageWidth - logoWidth) / 2;
  doc.addImage("/full-logo.png", "PNG", logoX, 15, logoWidth, logoHeight);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text("Transaction Receipt", margin, 35);

  doc.setFontSize(10);
  doc.text(`Created Date: ${formatDateTime(transaction.created)}`, margin, 45);
  doc.text(
    `Completed Date: ${formatDateTime(transaction.created)}`,
    margin,
    52,
  );

  doc.setFontSize(11);
  doc.setFont("helvetica", "bold");
  doc.text("Transfer overview", margin, 65);

  doc.setFillColor(247, 250, 252);
  doc.roundedRect(margin, 70, contentWidth, 25, 2, 2, "F");

  doc.setFontSize(9);
  doc.setTextColor(128, 128, 128);
  doc.text(`Amount Paid by ${sender}`, margin + 5, 78);
  doc.text(
    `Converted and sent to ${transaction.beneficiary.accountName}`,
    margin + contentWidth / 2,
    78,
  );

  doc.setFontSize(11);
  doc.setFont("helvetica", "bold");
  doc.setTextColor(0, 0, 0);
  doc.text(
    `${formatAmount(transaction.amount)} ${safeText(transaction.currency)}`,
    margin + 5,
    88,
  );
  // doc.text(
  //   `${formatAmount(transaction.quoteAmount)} ${safeText(transaction.quoteCurrency)}`,
  //   margin + contentWidth / 2,
  //   88,
  // );

  doc.setFontSize(11);
  doc.text("Exchange transaction confirmation", margin, 105);

  // Exchange details box - increased height from 35 to 45
  doc.setFillColor(247, 250, 252);
  doc.roundedRect(margin, 110, contentWidth, 45, 2, 2, "F");

  doc.setFontSize(9);
  doc.setFont("helvetica", "normal");
  doc.setTextColor(128, 128, 128);
  doc.text("Amount", margin + 5, 118);
  doc.text("Exchange amount", margin + contentWidth / 2, 118);

  doc.setFontSize(11);
  doc.setFont("helvetica", "bold");
  doc.setTextColor(0, 0, 0);
  doc.text(
    `${formatAmount(transaction.amount)} ${safeText(transaction.currency)}`,
    margin + 5,
    128,
  );
  // doc.text(
  //   `${formatAmount(transaction.quoteAmount)} ${safeText(transaction.quoteCurrency)}`,
  //   margin + contentWidth / 2,
  //   128,
  // );

  doc.setFontSize(9);
  doc.setFont("helvetica", "normal");
  doc.setTextColor(128, 128, 128);
  doc.text("Exchange Rate", margin + 5, 138);

  // Calculate the exchange rate with 5 decimal places
  const calculateRate = (baseAmount: number, quoteAmount: number): string => {
    const rate = (quoteAmount / baseAmount).toFixed(5);
    return rate;
  };

  // Set back to bold for the rate value
  // doc.setFontSize(11);
  // doc.setFont("helvetica", "bold");
  // doc.setTextColor(0, 0, 0);
  // doc.text(
  //   `1.00 ${safeText(transaction.baseCurrency)} = ${transaction.rate.finalRate} ${safeText(transaction.quoteCurrency)}`,
  //   margin + 5,
  //   145,
  // );

  doc.setTextColor(0, 0, 0);
  doc.setFontSize(11);
  doc.text("Sent to", margin, 165);

  doc.setFillColor(247, 250, 252);
  doc.roundedRect(margin, 170, contentWidth, 45, 2, 2, "F");

  doc.setFontSize(9);
  doc.setFont("helvetica", "normal");
  doc.setTextColor(128, 128, 128);
  doc.text("Name", margin + 5, 178);
  doc.text("Reference", margin + contentWidth / 2, 178);

  doc.setFontSize(11);
  doc.setFont("helvetica", "bold");
  doc.setTextColor(0, 0, 0);
  doc.text(safeText(transaction.beneficiary.accountName).toUpperCase(), margin + 5, 188);
  doc.text(safeText(transaction.reference), margin + contentWidth / 2, 188);

  doc.setFontSize(9);
  doc.setFont("helvetica", "normal");
  doc.setTextColor(128, 128, 128);
  doc.text("Account details", margin + 5, 198);

  // doc.setFontSize(11);
  // doc.setFont("helvetica", "bold");
  // doc.setTextColor(0, 0, 0);
  // doc.text(transaction.accountNumber, margin + 5, 205);
  // const bankName = doc.splitTextToSize(
  //   transaction.institution.toUpperCase(),
  //   contentWidth / 2 - 10,
  // );
  // doc.text(bankName, margin + 5, 212);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(10);
  doc.setTextColor(128, 128, 128);
  doc.text(
    "Thank you for choosing Passportmonie",
    (pageWidth - doc.getTextWidth("Thank you for choosing Passportmonie")) / 2,
    240,
  );

  const filename = `PassportMonie-Receipt-${transaction.reference}.pdf`;
  doc.save(filename);
}
