export type TransactionStatus =
  | "INITIATED"
  | "PAYMENT_SENT"
  | "PAYMENT_CONFIRMED"
  | "IN_ESCROW"
  | "PAYOUT_INITIATED"
  | "PAYOUT_SENT"
  | "COMPLETED"
  | "CANCELLED"
  | "FAILED"
  | "PENDING"
  | "IN_DISPUTE"
  | "RESOLVED"
  | "SUCCESSFUL";

export const statusColors = {
  completed: "bg-emerald-50 text-emerald-600",
  failed: "bg-red-50 text-red-600",
  pending: "bg-amber-50 text-amber-600",
};

export const additionalClass = {
  FAILED: statusColors.failed,
  CANCELLED: statusColors.failed,
  PENDING: statusColors.pending,
  INITIATED: statusColors.pending,
  PAYMENT_SENT: statusColors.pending,
  PAYMENT_CONFIRMED: statusColors.pending,
  IN_ESCROW: statusColors.pending,
  PAYOUT_INITIATED: statusColors.pending,
  PAYOUT_SENT: statusColors.pending,
  IN_DISPUTE: statusColors.pending,
  RESOLVED: statusColors.pending,
  SUCCESSFUL: statusColors.completed,
  COMPLETED: statusColors.completed,
};

export const standardDateFormat = (isoDateString: string): string => {
  const date = new Date(isoDateString);
  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.getFullYear();
  const ordinalSuffix = (day: number) => {
    if (day % 10 === 1 && day !== 11) return "st";
    if (day % 10 === 2 && day !== 12) return "nd";
    if (day % 10 === 3 && day !== 13) return "rd";
    return "th";
  };

  return `${day}${ordinalSuffix(day)} ${month}, ${year}`;
};

export const formatDate = (isoDateString: string): string => {
  const date = new Date(isoDateString);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return "Today";
  }

  if (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  ) {
    return "Yesterday";
  }

  return standardDateFormat(isoDateString);
};

export const formatDateTime = (isoDateTimeString: string): string => {
  const date = new Date(isoDateTimeString);
  const time = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return `${standardDateFormat(isoDateTimeString)} at ${time}`;
};
