import {
  ClipboardIcon,
  ShareIcon,
  ArrowLeftIcon,
  CheckIcon,
  GiftIcon,
} from "@heroicons/react/24/outline";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import { useAppState } from "../../../contexts/AppContext";

export const Route = createFileRoute("/_app/account/referralInfo")({
  component: ReferralInfo,
});

function ReferralInfo() {
  const {
    state: { user },
  } = useAppState();
  const referralCode = user?.referralCode;

  const [hasCopied, setHasCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralCode || "");
    setHasCopied(true);

    setTimeout(() => {
      setHasCopied(false);
    }, 2000);
  };

  const goToPreviousPage = () => {
    window.history.back();
  };

  const shareReferral = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Join me and get rewards!",
          text: `Hey there! 🎉  
                  Join me on Passportmonie and enjoy amazing rewards! 
                  Use my referral code ${referralCode} during signup to get ₦500 off your first transaction.  
                  Sign up now and let’s earn together!`,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      alert("Sharing not supported on this browser.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center p-4 pb-16">
      <div className="w-full flex items-center mb-4">
        <button
          className="text-gray-700 text-base font-medium flex items-center"
          onClick={goToPreviousPage}
        >
          <ArrowLeftIcon className="h-5 w-5 mr-2" />
          Back
        </button>
      </div>

      <div className="bg-blue-500/20 shadow-md p-5 w-full max-w-sm text-center rounded-lg">
        <div className="flex justify-center mb-4">
          <div className="w-20 h-20 bg-yellow-100 flex items-center justify-center rounded-full">
            <GiftIcon className="h-12 w-12 text-yellow-500" />
          </div>
        </div>
        {referralCode && (
          <div className="flex flex-col items-center p-4 rounded-lg text-base font-medium bg-primary text-white space-y-2 w-fit mx-auto">
            <div className="flex items-center">
              <span className="mr-4">{referralCode}</span>
              <div className="flex items-center space-x-4">
                <button
                  onClick={copyToClipboard}
                  className="hover:bg-white-100 rounded-full"
                >
                  {hasCopied ? (
                    <CheckIcon className="w-5 h-5 text-green-500" />
                  ) : (
                    <ClipboardIcon className="w-5 h-5 text-white-400" />
                  )}
                </button>
                <div className="h-6 w-px bg-white" />
                <ShareIcon
                  onClick={shareReferral}
                  className="h-6 w-6 text-white cursor-pointer hover:text-green-400"
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="shadow-md p-5 w-full max-w-sm text-start rounded-lg mt-4 bg-white">
        <h2 className="text-base font-bold text-gray-800 mb-3">
          Invite a friend and get
        </h2>
        <div className="flex flex-col space-y-6">
          <div className="flex items-start relative">
            <div className="flex flex-col items-center">
              <div className="p-2 bg-blue-500/20  rounded-md shadow-sm">
                <div className="w-3 h-3 bg-primary rounded-full" />
              </div>
              <div className="h-10 w-px bg-primary absolute top-7 left-7/2" />
            </div>
            <div className="ml-4">
              <p className="text-base font-bold text-gray-900">₦500</p>
              <p className="text-sm text-gray-500">
                on their first transaction
              </p>
            </div>
          </div>
          <div className="flex items-start">
            <div className="p-2 bg-blue-500/20 rounded-md shadow-sm">
              <div className="w-3 h-3 bg-primary rounded-full" />
            </div>
            <div className="ml-4">
              <p className="text-base font-bold text-gray-900">₦500</p>
              <p className="text-sm text-gray-500">
                on their second transaction
              </p>
            </div>
          </div>
        </div>

        <h2 className="text-base font-bold text-gray-800 mt-5 mb-3">
          Your friend gets
        </h2>
        <div className="flex items-start">
          <div className="p-2 bg-blue-500/20 rounded-md shadow-sm">
            <div className="w-3 h-3 bg-primary rounded-full" />
          </div>
          <div className="ml-4">
            <p className="text-base font-bold text-gray-900">₦500</p>
            <p className="text-sm text-gray-500">
              discount on their first transaction
            </p>
          </div>
        </div>
      </div>

      <div className="mt-5 pb-5">
        <button className="text-blue-500 font-medium hover:underline">
          View Referral History →
        </button>
      </div>
    </div>
  );
}

export default ReferralInfo;
