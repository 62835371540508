import { createFileRoute, Link } from "@tanstack/react-router";
import {
  ChevronRightIcon,
  // IdentificationIcon,
  ChatBubbleLeftRightIcon,
  MegaphoneIcon,
} from "@heroicons/react/24/outline";
import { useCallback } from "react";
import { useNavigate } from "@tanstack/react-router";
import { signOut } from "firebase/auth";
import { useQueryClient } from "@tanstack/react-query";

import { auth } from "../../../utils/firebase";
import { useAppState, LOGOUT } from "../../../contexts/AppContext";
import PageTransition from "../../../components/PageTransition";
import AccountHeader from "../../../components/account/AccountHeader";

export const Route = createFileRoute("/_app/account/")({
  component: Account,
});

function Account() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { dispatch } = useAppState();
  // const [searchQuery, setSearchQuery] = useState('');
  const {
    state: { user },
  } = useAppState();
  // const isVerified = user?.hasVerifiedID;

  const whatsappNumber = import.meta.env.VITE_WHATSAPP_SUPPORT_NUMBER;
  const whatsappLink = `https://wa.me/${whatsappNumber}`;

  // // Define searchable items
  // const menuItems = [
  //   { title: 'Update KYC Information', section: 'IDENTITY & KYC UPDATES', href: '/kyc', subtitle: 'Unlock account limits' },
  //   { title: 'Edit Profile', section: 'IDENTITY & KYC UPDATES', href: '/profile' },
  //   { title: 'Add Bio Data', section: 'IDENTITY & KYC UPDATES', href: '/bio', subtitle: 'Add your bio data to your profile' },
  //   { title: 'NIN', section: 'IDENTITY & KYC UPDATES', href: '/nin', subtitle: 'Add your NIN to secure your profile' },
  //   { title: 'Passportmonie Beneficiaries', section: 'CONTACTS & PEOPLE', href: '/beneficiaries' },
  //   { title: 'People you follow', section: 'CONTACTS & PEOPLE', href: '/following' },
  //   // ... add other menu items
  // ];

  // // Filter items based on search
  // const filteredItems = searchQuery.trim()
  //   ? menuItems.filter(item =>
  //       item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       item.subtitle?.toLowerCase().includes(searchQuery.toLowerCase())
  //     )
  //   : [];

  const handleLogout = useCallback(async () => {
    try {
      await signOut(auth);
      queryClient.removeQueries();
      localStorage.clear();
      dispatch({ type: LOGOUT });

      setTimeout(() => {
        navigate({ to: "/login" });
      }, 0);
    } catch (error) {
      console.error("Logout error:", error);
    }
  }, [navigate, queryClient, dispatch]);

  return (
    <PageTransition>
      <div className="min-h-screen bg-gray-50 flex flex-col">
        <AccountHeader user={user} />
        {/* Search Section */}
        {/* <div className="p-4">
        <div className="bg-gray-100 rounded-lg p-3">
          <input 
            type="text" 
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Find a setting"
            className="w-full bg-transparent outline-none text-gray-600 placeholder-gray-500"
          />
        </div>
        
        {/* Search Results */}
        {/* {searchQuery.trim() && (
          <div className="mt-2 bg-white rounded-lg shadow-sm">
            {filteredItems.length > 0 ? (
              filteredItems.map((item, index) => (
                <a 
                  key={index}
                  href={item.href}
                  className="flex items-center justify-between p-4 border-b last:border-b-0"
                >
                  <div>
                    <div className="text-gray-800">{item.title}</div>
                    {item.subtitle && (
                      <div className="text-sm text-gray-500">{item.subtitle}</div>
                    )}
                    <div className="text-xs text-gray-400">{item.section}</div>
                  </div>
                  <ChevronRightIcon className="w-5 h-5 text-gray-400" />
                </a>
              ))
            ) : (
              <div className="p-4 text-center text-gray-500">
                No settings found
              </div>
            )}
          </div>
        )}
      </div> */}

        {/* Only show main content when not searching */}
        {/* {!searchQuery.trim() && ( */}
        <div className="flex-1 p-4">
          {/* Identity & KYC Section */}
          {/* <div className="mb-6">
          <h2 className="text-sm text-gray-500 mb-2">IDENTITY</h2>
          <div className="bg-white rounded-lg">
            <a
              href="/kyc"
              className="flex items-center justify-between p-4 border-b"
            >
              <div className="flex items-center space-x-3">
                <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                  {isVerified ? (
                    <IdentificationIcon className="w-6 h-6 text-green-600" />
                  ) : (
                    <IdentificationIcon className="w-6 h-6 text-gray-600" />
                  )}
                </div>
                <div>
                  {isVerified ? (
                    <div className="text-green-800">Verified</div>
                  ) : (
                    <div className="text-gray-800">
                      KYC Verification Incomplete
                    </div>
                  )}
                </div>
              </div>
              {!isVerified && (
                <ChevronRightIcon className="w-5 h-5 text-gray-400" />
              )}
            </a>

            <a href="/profile" className="flex items-center justify-between p-4 border-b">
                <div className="flex items-center space-x-3">
                  <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                    <UserIcon className="w-6 h-6 text-gray-600" />
                  </div>
                  <div className="text-gray-800">Edit Profile</div>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-gray-400" />
              </a>

              <a href="/bio" className="flex items-center justify-between p-4 border-b">
                <div className="flex items-center space-x-3">
                  <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                    <DocumentTextIcon className="w-6 h-6 text-gray-600" />
                  </div>
                  <div>
                    <div className="text-gray-800">Add Bio Data</div>
                    <div className="text-sm text-gray-500">Add your bio data to your profile</div>
                  </div>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-gray-400" />
              </a>

              <a href="/nin" className="flex items-center justify-between p-4">
                <div className="flex items-center space-x-3">
                  <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                    <FingerPrintIcon className="w-6 h-6 text-gray-600" />
                  </div>
                  <div>
                    <div className="text-gray-800">NIN</div>
                    <div className="text-sm text-gray-500">Add your NIN to secure your profile</div>
                  </div>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-gray-400" />
              </a>
          </div>
        </div> */}

          {/* Rewards Section */}
          <div className="mb-6">
            <h2 className="text-sm text-gray-500 mb-2">REWARDS</h2>
            <Link
              className="bg-white rounded-lg p-4 flex items-center justify-between cursor-pointer"
              to="/account/referralInfo"
            >
              <div className="flex items-center space-x-3">
                <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                  <MegaphoneIcon className="w-6 h-6 text-gray-600" />
                </div>
                <div className="text-gray-800">Referrals</div>
              </div>
              <div className="flex items-center space-x-2">
                <ChevronRightIcon className="w-5 h-5 text-gray-400" />
              </div>
            </Link>
          </div>

          {/* Contacts & People Section */}
          {/* <div className="mb-6">
            <h2 className="text-sm text-gray-500 mb-2">CONTACTS & PEOPLE</h2>
            <div className="bg-white rounded-lg">
              <a href="/beneficiaries" className="flex items-center justify-between p-4 border-b">
                <div className="flex items-center space-x-3">
                  <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                    <StarIcon className="w-6 h-6 text-gray-600" />
                  </div>
                  <div className="text-gray-800">Passportmonie Beneficiaries</div>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-gray-400" />
              </a>

              <a href="/following" className="flex items-center justify-between p-4">
                <div className="flex items-center space-x-3">
                  <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                    <UsersIcon className="w-6 h-6 text-gray-600" />
                  </div>
                  <div className="text-gray-800">People you follow</div>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-gray-400" />
              </a>
            </div>
          </div> */}

          {/* Finance & Rewards Section */}
          {/* <div className="mb-6">
            <h2 className="text-sm text-gray-500 mb-2">FINANCE & REWARDS</h2>
            <div className="bg-white rounded-lg">
              <a href="/statement" className="flex items-center justify-between p-4 border-b">
                <div className="flex items-center space-x-3">
                  <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                    <BanknotesIcon className="w-6 h-6 text-gray-600" />
                  </div>
                  <div>
                    <div className="text-gray-800">Generate Account Statement</div>
                    <div className="text-sm text-gray-500">See your transaction history within a period of time</div>
                  </div>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-gray-400" />
              </a>

              <a href="/reference" className="flex items-center justify-between p-4 border-b">
                <div className="flex items-center space-x-3">
                  <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                    <DocumentIcon className="w-6 h-6 text-gray-600" />
                  </div>
                  <div>
                    <div className="text-gray-800">Generate Reference Letter</div>
                    <div className="text-sm text-gray-500">Generate a reference letter you can use for applications</div>
                  </div>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-gray-400" />
              </a>

              <a href="/bank-accounts" className="flex items-center justify-between p-4 border-b">
                <div className="flex items-center space-x-3">
                  <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                    <BuildingLibraryIcon className="w-6 h-6 text-gray-600" />
                  </div>
                  <div>
                    <div className="text-gray-800">Bank Accounts</div>
                    <div className="text-sm text-gray-500">Save your account as the major beneficiary</div>
                  </div>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-gray-400" />
              </a>

               <div className="flex items-center justify-between p-4 border-b">
                <div className="flex items-center space-x-3">
                  <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                    <EyeSlashIcon className="w-6 h-6 text-gray-600" />
                  </div>
                  <div className="text-gray-800">Hide Cash Balance</div>
                </div>
                <Switch
                  checked={hideBalance}
                  onChange={setHideBalance}
                  className={`${
                    hideBalance ? 'bg-green-500' : 'bg-gray-200'
                  } relative inline-flex h-6 w-11 items-center rounded-full transition-colors`}
                >
                  <span className={`${
                    hideBalance ? 'translate-x-6' : 'translate-x-1'
                  } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                  />
                </Switch>
              </div> 

              
            </div>
          </div> */}

          {/* Security & Support Section */}
          <div className="mb-8">
            <h2 className="text-sm text-gray-500 mb-2">SUPPORT</h2>
            <div className="bg-white rounded-lg">
              {/* <a href="/notifications" className="flex items-center justify-between p-4 border-b">
                <div className="flex items-center space-x-3">
                  <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                    <BellIcon className="w-6 h-6 text-gray-600" />
                  </div>
                  <div className="text-gray-800">Notifications</div>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-gray-400" />
              </a> */}

              <a
                href={whatsappLink}
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-between p-4"
              >
                <div className="flex items-center space-x-3">
                  <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                    <ChatBubbleLeftRightIcon className="w-6 h-6 text-gray-600" />
                  </div>
                  <div className="text-gray-800">Chat with us</div>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-gray-400" />
              </a>

              {/* <a href="/pin" className="flex items-center justify-between p-4 border-b">
                <div className="flex items-center space-x-3">
                  <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                    <LockClosedIcon className="w-6 h-6 text-gray-600" />
                  </div>
                  <div className="text-gray-800">Change PIN</div>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-gray-400" />
              </a> */}

              {/* <a href="/support" className="flex items-center justify-between p-4 border-b">
                <div className="flex items-center space-x-3">
                  <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                    <QuestionMarkCircleIcon className="w-6 h-6 text-gray-600" />
                  </div>
                  <div className="text-gray-800">Help & Support</div>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-gray-400" />
              </a> */}

              {/* <a href="/about" className="flex items-center justify-between p-4">
                <div className="flex items-center space-x-3">
                  <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                    <InformationCircleIcon className="w-6 h-6 text-gray-600" />
                  </div>
                  <div className="text-gray-800">About Passportmonie App</div>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-gray-400" />
              </a> */}
            </div>
          </div>
        </div>
        {/* )} */}

        {/* Footer - Updated for bottom nav */}
        <div className="fixed bottom-16 left-0 right-0 bg-gray-50 px-4 pb-4 pt-2">
          <button
            onClick={handleLogout}
            className="w-full py-3 text-red-500 bg-white rounded-lg text-lg font-bold border border-black-500 rounded-lg mb-2"
          >
            Logout
          </button>

          <p className="text-center text-sm text-gray-500 mb-2">
            <a
              href="https://passportmonie.notion.site/Terms-and-Conditions-a2a4f5f38e424baba70f31345b8cda39"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              Terms
            </a>{" "}
            and
            <a
              href="https://passportmonie.notion.site/Privacy-Policy-56f4825897154b4285ab5abd3fd02db6"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              {" "}
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </PageTransition>
  );
}
