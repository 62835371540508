import { createContext, useContext, ReactNode, useReducer, useEffect } from "react";

import { Currency, PaymentMethod, WithdrawalType } from "../constants";
import { Beneficiary } from "../services/queries/beneficiaries/types";

export interface TransactionState {
  baseCurrency: Currency;
  quoteCurrency: Currency;
  baseAmount: string;
  quoteAmount: string;
  rateId: string | undefined;
  remarks: string;
  walletId: string | undefined;
  withdrawalType: WithdrawalType;
  withdrawalFee: number | undefined;
  beneficiary: Beneficiary | null;
  accountName: string;
  accountNumber: string;
  bankCode: string | undefined;
  bankName: string | undefined;
  paymentMethod: PaymentMethod | undefined;
  saveBeneficiary: boolean;
}

export type TransactionContextType = {
  state: TransactionState;
  dispatch: React.Dispatch<TransactionAction>;
};

export const SET_TRANSACTION_FORM_DATA = "SET_TRANSACTION_FORM_DATA";
export const RESET_TRANSACTION_FORM_DATA = "RESET_TRANSACTION_FORM_DATA";

export type TransactionAction =
  | {
    type: typeof SET_TRANSACTION_FORM_DATA;
    payload: TransactionState;
  }
  | {
    type: typeof RESET_TRANSACTION_FORM_DATA;
  };

const initialState: TransactionState = {
  baseCurrency: Currency.NGN,
  quoteCurrency: Currency.KES,
  baseAmount: "",
  quoteAmount: "",
  rateId: undefined,
  walletId: undefined,
  withdrawalType: WithdrawalType.INTERNATIONAL,
  withdrawalFee: undefined,
  remarks: "",
  accountName: "",
  accountNumber: "",
  bankCode: undefined,
  bankName: undefined,
  paymentMethod: undefined,
  beneficiary: null,
  saveBeneficiary: false,
};

const STORAGE_KEY = 'transactionState';

const getInitialState = (): TransactionState => {
  const savedState = localStorage.getItem(STORAGE_KEY);
  return savedState ? JSON.parse(savedState) : initialState;
};

const TransactionContext = createContext<TransactionContextType>({
  state: initialState,
  dispatch: () => { },
});

function reducer(
  state: TransactionState,
  action: TransactionAction,
): TransactionState {
  let newState: TransactionState;

  switch (action.type) {
    case SET_TRANSACTION_FORM_DATA:
      newState = { ...state, ...action.payload };
      break;
    case RESET_TRANSACTION_FORM_DATA:
      newState = { ...initialState };
      break;
    default:
      return state;
  }

  // Persist to localStorage after state changes
  localStorage.setItem(STORAGE_KEY, JSON.stringify(newState));

  return newState;
}

export default function TransactionProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [state, dispatch] = useReducer(reducer, getInitialState());

  // Clear localStorage when component unmounts
  useEffect(() => {
    return () => {
      localStorage.removeItem(STORAGE_KEY);
    };
  }, []);

  return (
    <TransactionContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
}

export const useTransaction = () => {
  const context = useContext(TransactionContext);
  return context;
};
