import { FirebaseError } from "@firebase/util";
import { AxiosError } from "axios";

interface Route {
  path: string;
  title: string;
  slug: string;
  iconUrl?: string;
  isAppRoute: boolean;
  showInSideNav?: boolean;
  showInBottomNav?: boolean;
}

export const routes: Route[] = [
  {
    path: "/login",
    slug: "login",
    title: "Login",
    isAppRoute: false,
  },
  {
    path: "/dashboard",
    slug: "dashboard",
    title: "Home",
    isAppRoute: true,
    showInSideNav: true,
    showInBottomNav: true,
  },
  {
    path: "/transactions",
    slug: "transactions",
    title: "Transactions",
    isAppRoute: true,
    showInSideNav: true,
    showInBottomNav: true,
  },
  {
    path: "/cards",
    slug: "cards",
    title: "Cards",
    isAppRoute: true,
    showInSideNav: true,
    showInBottomNav: true,
  },
  {
    path: "/account",
    slug: "account",
    title: "Account",
    isAppRoute: true,
    showInSideNav: true,
    showInBottomNav: true,
  },
];

export const usersCollection = "users";

export const baseUrl = import.meta.env.VITE_SERVER_BASE_URL;

export const Terms =
  "https://passportmonie.notion.site/Terms-and-Conditions-a2a4f5f38e424baba70f31345b8cda39";
export const PrivacyPolicy =
  "https://passportmonie.notion.site/Privacy-Policy-56f4825897154b4285ab5abd3fd02db6";

export enum Currency {
  NGN = "NGN",
  USD = "USD",
  KES = "KES",
  GHS = "GHS",
  RWF = "RWF",
  TZS = "TZS",
  UGX = "UGX",
  XOF = "XOF",
}

export const currencies = [
  { value: Currency.NGN, label: `🇳🇬 ${Currency.NGN}`, isActive: true },
  { value: Currency.KES, label: `🇰🇪 ${Currency.KES}`, isActive: true },
  { value: Currency.GHS, label: `🇬🇭 ${Currency.GHS}`, isActive: true },
  { value: Currency.RWF, label: `🇷🇼 ${Currency.RWF}`, isActive: false },
  { value: Currency.TZS, label: `🇹🇿 ${Currency.TZS}`, isActive: false },
  { value: Currency.UGX, label: `🇺🇬 ${Currency.UGX}`, isActive: false },
  { value: Currency.XOF, label: `🇨🇮 ${Currency.XOF}`, isActive: false },
];

export interface CurrencyItem {
  value: Currency;
  label: string;
}

export enum PaymentMethod {
  BANK = "BANK",
  MOBILE_MONEY = "MOBILE_MONEY",
}

export const currencyDetails = [
  {
    currency: Currency.NGN,
    canSend: [
      { value: Currency.KES, label: `🇰🇪 ${Currency.KES}` },
      { value: Currency.GHS, label: `🇬🇭 ${Currency.GHS}` },
    ],
    paymentMethods: [{ value: PaymentMethod.BANK, label: PaymentMethod.BANK }],
    countryCode: "NG",
  },
  {
    currency: Currency.KES,
    canSend: [],
    paymentMethods: [
      { value: PaymentMethod.MOBILE_MONEY, label: PaymentMethod.MOBILE_MONEY },
    ],
    countryCode: "KE",
  },
  {
    currency: Currency.GHS,
    canSend: [],
    paymentMethods: [{ value: PaymentMethod.MOBILE_MONEY, label: PaymentMethod.MOBILE_MONEY }],
    countryCode: "GH",
  },
];

export enum BeneficiaryType {
  PERSONAL = 'PERSONAL',
  BUSINESS = 'BUSINESS',
}

export const firebaseCodes = {
  wrongPassword: "auth/wrong-password",
  noUser: "auth/user-not-found",
  emailExists: "auth/email-already-in-use",
  networkError: "auth/network-request-failed",
};

export const errorMessages = {
  invalidLogin: "Incorrect Login details",
  emailExists: "Email registered already",
  networkError:
    "Network Connection error. Pls check your connection and ty again",
};

export const getAuthErrorMessage = (error: unknown): string => {
  if (error instanceof FirebaseError) {
    switch (error.code) {
      case firebaseCodes.noUser:
      case firebaseCodes.wrongPassword:
        return errorMessages.invalidLogin;

      case firebaseCodes.emailExists:
        return errorMessages.emailExists;

      case firebaseCodes.networkError:
        return errorMessages.networkError;

      default:
        return error.message;
    }
  }

  if (error instanceof AxiosError) {
    return error.response?.data.message;
  }

  return "An error occured. Please try again later.";
};

export enum TransactionStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  SUCCESSFUL = "SUCCESSFUL",
  FAILED = "FAILED",
}

export enum WithdrawalType {
  INTERNATIONAL = "INTERNATIONAL",
  LOCAL = "LOCAL",
}
