import { BellIcon } from "@heroicons/react/24/outline";

interface DashboardHeaderWidgetProps {
  firstName?: string;
  lastName?: string;
  avatarUrl?: string;
}

export function DashboardHeaderWidget({
  firstName,
  lastName,
  avatarUrl,
}: DashboardHeaderWidgetProps) {
  return (
    <div className="flex justify-between items-center mb-8">
      {avatarUrl ? (
        <img src={avatarUrl} alt="Profile" className="w-8 h-8 rounded-full" />
      ) : (
        <div className="w-10 h-10 rounded-full bg-white/20 flex items-center justify-center">
          {firstName?.[0]}
          {lastName?.[0]}
        </div>
      )}

      <button className="relative" type="button" title="button">
        <BellIcon className="w-6 h-6" />
        <span className="absolute -top-1 -right-1 w-2 h-2 bg-red-500 rounded-full" />
      </button>
    </div>
  );
}
