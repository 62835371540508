import { ClipboardIcon } from "@heroicons/react/24/outline";
import { copyToClipboard } from "../../utils";
import { showToast } from "../../utils/toast";

interface CopyButtonProps {
  toastMessage?: string;
  textToCopy: string;
}

const CopyButton = ({
  textToCopy,
  toastMessage = "Copied to Clipboard",
}: CopyButtonProps) => {
  return (
    <button 
    className="p-2 hover:bg-gray-100 rounded-full" 
    onClick={() =>
      copyToClipboard(textToCopy, () => showToast(toastMessage, "info"))
    }
    >
          <ClipboardIcon className="h-5 w-5 text-gray-500" />
        </button>
  );
};

export default CopyButton;
