import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { showToast } from "../utils/toast";

const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {},
  },
  queryCache: new QueryCache({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      let message = error?.response?.data?.message || error.message;
      if (error.code === "ECONNABORTED") {
        message = "Request timed out due to network connection. Please refresh the page or try again.";
      }

      showToast(message, "error");
    },
  }),
});

type Props = {
  children: React.ReactNode;
};

const QueryProvider = ({ children }: Props) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {process.env.NODE_ENV === "staging" && (
        <ReactQueryDevtools position="bottom-right" />
      )}
    </QueryClientProvider>
  );
};

export default QueryProvider;
