import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import {
  signOut,
  signInWithEmailAndPassword,
  getAuth,
  createUserWithEmailAndPassword,
  Auth,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { Messaging, getMessaging, getToken } from "firebase/messaging";

import { User } from "../../services/queries/user/types";
import { usersCollection } from "../../constants/index";

import { firebaseConfig, getInvalidConfigKeys, isConfigValid } from "./config";
import { logError } from "../sentry";

let auth: Auth,
  messaging: Messaging,
  accessTokenPushNotification: string | undefined;

if (!firebase.apps.length) {
  try {
    const invalidKeys = getInvalidConfigKeys();
    if (invalidKeys.length) {
      throw new Error(
        `Firebase configuration is missing. Please check your environment variables. ${invalidKeys.join(', ')}`,
      );
    }

    firebase.initializeApp(firebaseConfig);
  } catch (err) {
    logError(err as Error);
    console.error("Firebase initialization error:", err);
  }
}

try {
  auth = getAuth();
  setPersistence(auth, browserLocalPersistence);
} catch (error) {
  logError(error as Error);
}

try {
  messaging = getMessaging();
} catch (error) {
  logError(error as Error);
}

const login = signInWithEmailAndPassword;
const signUp = createUserWithEmailAndPassword;

async function saveTokenToDatabase(token: string, userId: string) {
  try {
    await firebase
      .firestore()
      .collection(usersCollection)
      .doc(userId)
      .update({
        webTokens: firebase.firestore.FieldValue.arrayUnion(token),
      });
  } catch (error) {
    logError(error as Error);
  }
}

async function getTokenAsync(messaging: Messaging, user: User | null) {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: import.meta.env.VITE_FIREBASE_MESSAGING_VAPID_KEY,
    });
    if (currentToken) {
      accessTokenPushNotification = currentToken;
    }
    if (user) {
      return saveTokenToDatabase(currentToken, user.id);
    }
  } catch (err) {
    logError(err as Error);
  }
}

async function getUserToken() {
  try {
    const user = auth.currentUser;
    if (!user) {
      return null;
    }

    return user.getIdToken();
  } catch (err) {
    logError(err as Error);
  }
}

export {
  auth,
  signUp,
  login,
  signOut,
  messaging,
  getTokenAsync,
  getUserToken,
  accessTokenPushNotification, createUserWithEmailAndPassword,
};
