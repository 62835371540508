import { BeneficiaryType, WithdrawalType } from "../constants";
import { currencyDetails } from "../constants";
import { TransactionState } from "../contexts/TransactionContext";
import { WithdrawalRequest } from "../services/queries/wallets/types";

export enum WalletStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  INACTIVE = "INACTIVE",
  ACTIVE = "ACTIVE",
}

export interface TransferConfig {
  id: string;
  currency: string;
  fee: number;
  minAmount: number;
  maxAmount: number;
}

export const buildWithdrawalRequest = ({ baseCurrency, quoteCurrency, baseAmount, rateId, withdrawalType, accountName, accountNumber, bankCode, paymentMethod, saveBeneficiary }: TransactionState): WithdrawalRequest => {
  if (!paymentMethod) {
    throw new Error("Payment method not found");
  }

  if (!bankCode) {
    throw new Error("Bank code not found");
  }

  const currency = withdrawalType === WithdrawalType.INTERNATIONAL ? quoteCurrency : baseCurrency;
  const countryCode = currencyDetails.find(item => item.currency === currency)?.countryCode;

  if (!countryCode) {
    throw new Error("Country code not found");
  }

  const beneficiary = {
    beneficiaryType: BeneficiaryType.PERSONAL,
    paymentMethod,
    accountName,
    accountNumber,
    bankCode,
    countryCode,
    currency,
  }

  const request: WithdrawalRequest = {
    amount: Number(baseAmount),
    withdrawalType: withdrawalType,
    beneficiary,
    saveBeneficiary,
  };

  // if international, add more data
  if (withdrawalType === WithdrawalType.INTERNATIONAL) {
    request.rateId = rateId;
  }

  return request;
};
