import { useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

import useClickOutside from "../../../hooks/useClickOutside";
import { CurrencyItem } from "../../../constants";

interface TransferInputProps {
  label: string;
  balance?: number;
  amount: string;
  onAmountChange: (value: string) => void;
  onCurrencySelected: (selectedCountry: CurrencyItem) => void;
  currencies: CurrencyItem[] | undefined;
  selectedCurrency: CurrencyItem | undefined;
  showCurrencySelect?: boolean;
}

export default function TransferInput({
  label,
  balance,
  amount,
  onAmountChange,
  currencies,
  onCurrencySelected,
  selectedCurrency,
  showCurrencySelect = true,
}: TransferInputProps) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });

  const handleCurrencySelected = (selectedCurrency: CurrencyItem) => {
    onCurrencySelected(selectedCurrency);
    setIsOpen(false);
  };

  return (
    <div className="bg-white rounded-lg p-4 mb-4 border border-gray-200 w-full">
      <div className="flex justify-between w-full">
        <div className="flex-1">
          <span className="text-sm text-green-600">{label}</span>
          <NumericFormat
            value={amount}
            onValueChange={input => onAmountChange(input.value)}
            thousandSeparator=","
            className="text-2xl font-bold w-full focus:outline-none"
            placeholder="0"
            maxLength={10}
          />
        </div>

        <div className="flex flex-col items-end justify-center">
          {showCurrencySelect && (
            <div className="currency-selector">
              <div ref={dropdownRef} className="relative">
                <button
                  className="flex items-center justify-between gap-2 px-4 py-2 rounded-full border border-green-600 w-full"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <span className="text-sm">{selectedCurrency?.label}</span>
                  <ChevronDownIcon className="w-4 h-4" />
                </button>

                {isOpen && (
                  <div className="absolute mt-2 w-36 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                    {currencies && currencies.map((currency) => (
                      <div
                        key={currency.value}
                        onClick={() => handleCurrencySelected(currency)}
                        className="flex items-center gap-2 px-4 py-2 hover:bg-gray-50 cursor-pointer"
                      >
                        <span>{currency.label}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}

          {balance && (
            <div className="text-xs text-green-500 font-semibold mt-2">
              {balance?.toLocaleString("en-US")} {selectedCurrency?.value}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
