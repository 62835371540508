import { createFileRoute, Link } from "@tanstack/react-router";
import { motion } from "framer-motion";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import PageTransition from "../../../components/PageTransition";

export const Route = createFileRoute("/_app/withdraw/success")({
  component: WithdrawalSuccessPage,
});

function WithdrawalSuccessPage() {
  return (
    <PageTransition>
      <div className="min-h-screen flex flex-col items-center justify-center p-4">
        <div className="max-w-md w-full mx-auto text-center">
          {/* Success Animation */}
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
            className="mb-6"
          >
            <CheckCircleIcon className="w-24 h-24 mx-auto text-green-500" />
          </motion.div>

          {/* Success Message */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <h1 className="text-2xl font-bold text-gray-900 mb-2">
              Withdrawal Successful!
            </h1>
            <p className="text-gray-600 mb-8">
              Your withdrawal request has been successfully submitted. You'll receive a confirmation once the funds are processed.
            </p>
          </motion.div>

          {/* Action Buttons */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="space-y-4"
          >
            <Link
              to="/"
              className="block w-full bg-blue-600 text-white rounded-lg px-4 py-3 font-medium hover:bg-blue-700 transition-colors"
            >
              Back to Dashboard
            </Link>

            <Link
              to="/transactions"
              className="block w-full bg-gray-100 text-gray-700 rounded-lg px-4 py-3 font-medium hover:bg-gray-200 transition-colors"
            >
              View Transactions
            </Link>
          </motion.div>

          {/* Additional Info */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6 }}
            className="mt-8 p-4 bg-blue-50 rounded-lg"
          >
            <p className="text-sm text-blue-600">
              Need help? Contact our support team
            </p>
          </motion.div>
        </div>
      </div>
    </PageTransition>
  );
}