import {
  createFileRoute,
  useRouter,
  useNavigate,
  useLocation,
} from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { GoArrowLeft } from "react-icons/go";

import Spacer from "../../../components/Spacer";
import PageTransition from "../../../components/PageTransition";
import {
  ratesResourcePath,
  useGetRates,
} from "../../../services/queries/rates";
import useFilters from "../../../hooks/useFilters";
import {
  SET_TRANSACTION_FORM_DATA,
  useTransaction,
} from "../../../contexts/TransactionContext";
import { currencies, Currency, currencyDetails, CurrencyItem, WithdrawalType } from "../../../constants";
import { WithdrawalForm } from "../../../components/wallets/WithdrawalForm";
import { useGetTransferConfig } from "../../../services/queries/wallets";
import useDebounce from "../../../hooks/useDebounce";

interface TransferSearchParams {
  currency?: string;
}

export const Route = createFileRoute("/_app/withdraw/")({
  validateSearch: (search: Record<string, unknown>): TransferSearchParams => {
    return {
      currency: search.currency as string,
    };
  },
  component: WithdrawPage,
});

function WithdrawPage() {
  const { history } = useRouter();
  const navigate = useNavigate();
  const location = useLocation();
  const { currency, balance, walletId } = location.state as { currency: Currency; balance: number; walletId: string };
  const { state: formData, dispatch } = useTransaction();
  const { withdrawalType, quoteCurrency, baseCurrency, quoteAmount, baseAmount, remarks } = formData;
  const { url } = useFilters(ratesResourcePath, {
    pageNumber: "1",
    pageSize: "12",
  });
  const { data, isLoading: isLoadingRates } = useGetRates(url.href, "1", "12");
  const rates = data?.data?.list;

  const exchangeRate = rates?.find(
    (rate) =>
      rate.currencyPair ===
      `${baseCurrency}/${quoteCurrency}`,
  );

  const transferAmount = withdrawalType === WithdrawalType.INTERNATIONAL ? quoteAmount : baseAmount;
  const transferCurrency = withdrawalType === WithdrawalType.INTERNATIONAL ? quoteCurrency : baseCurrency;

  const debouncedTransferAmount = useDebounce(transferAmount);

  const { data: transferConfigData, isLoading: isLoadingFee } = useGetTransferConfig(
    debouncedTransferAmount,
    transferCurrency
  );
  const transferConfig = transferConfigData?.data;
  const withdrawalFee = transferConfig?.fee;

  const handleWithdrawalTypeChange = (withdrawalType: WithdrawalType) => {
    dispatch({
      type: SET_TRANSACTION_FORM_DATA,
      payload: {
        ...formData,
        withdrawalType,
      },
    });
  };

  const calculateBaseAmount = (quoteAmount: string, rate: number): string => {
    if (!quoteAmount || !rate) {
      return "";
    }

    return Math.abs(Math.round(Number(quoteAmount) / rate)).toString();
  }

  const calculateQuoteAmount = (baseAmount: string, rate: number): string => {
    if (!baseAmount || !rate) {
      return "";
    }

    return Math.abs(Math.round(Number(baseAmount) * rate)).toString();
  }

  const handleBaseAmountChange = (baseAmount: string) => {
    if (!baseAmount) {
      dispatch({
        type: SET_TRANSACTION_FORM_DATA,
        payload: {
          ...formData,
          baseAmount: "",
          quoteAmount: "",
        },
      });
      return;
    }

    if (!exchangeRate?.finalRate) {
      return;
    }

    dispatch({
      type: SET_TRANSACTION_FORM_DATA,
      payload: {
        ...formData,
        baseAmount,
        quoteAmount: calculateQuoteAmount(baseAmount, exchangeRate.finalRate),
      },
    });
  };

  const handleQuoteAmountChange = (quoteAmount: string) => {
    if (!quoteAmount) {
      dispatch({
        type: SET_TRANSACTION_FORM_DATA,
        payload: {
          ...formData,
          baseAmount: "",
          quoteAmount: "",
        },
      });
      return;
    }

    if (!exchangeRate?.finalRate) {
      return;
    }

    dispatch({
      type: SET_TRANSACTION_FORM_DATA,
      payload: {
        ...formData,
        quoteAmount,
        baseAmount: calculateBaseAmount(quoteAmount, exchangeRate.finalRate),
      },
    });
  };

  const handleBaseCurrencyChange = (currency: CurrencyItem) => {
    dispatch({
      type: SET_TRANSACTION_FORM_DATA,
      payload: {
        ...formData,
        baseCurrency: currency.value,
      },
    });
  };

  const handleQuoteCurrencyChange = (currency: CurrencyItem) => {
    dispatch({
      type: SET_TRANSACTION_FORM_DATA,
      payload: {
        ...formData,
        quoteCurrency: currency.value,
      },
    });
  };

  const handleRemarksChange = (remarks: string) => {
    dispatch({
      type: SET_TRANSACTION_FORM_DATA,
      payload: {
        ...formData,
        remarks,
      },
    });
  };

  const handleSubmit = () => {
    dispatch({
      type: SET_TRANSACTION_FORM_DATA,
      payload: {
        ...formData,
        rateId: withdrawalType === WithdrawalType.INTERNATIONAL ? exchangeRate?.id : undefined,
      },
    });

    navigate({ to: "/withdraw/beneficiary" });
  };

  // set base currency on page load and when currency changes
  useEffect(() => {
    if (walletId && currency) {
      dispatch({
        type: SET_TRANSACTION_FORM_DATA,
        payload: {
          ...formData,
          baseCurrency: currency,
          walletId,
        },
      });
    }

  }, [currency, walletId, history]);

  // when the quote currency changes, re-calculate the quote amount
  useEffect(() => {
    if (!exchangeRate?.finalRate) {
      return;
    }

    dispatch({
      type: SET_TRANSACTION_FORM_DATA,
      payload: {
        ...formData,
        quoteAmount: calculateQuoteAmount(baseAmount, exchangeRate.finalRate),
      },
    });
  }, [quoteCurrency, exchangeRate]);


  useEffect(() => {
    if (!withdrawalFee) return;

    dispatch({
      type: SET_TRANSACTION_FORM_DATA,
      payload: {
        ...formData,
        withdrawalFee,
      },
    });
  }, [withdrawalFee]);

  const canSendCurrencies = currencyDetails.filter(
    (detail) => detail.canSend.length,
  );

  const baseCurrencies = currencies.filter(
    (currency) =>
      canSendCurrencies.some((detail) => detail.currency === currency.value),
  );

  const quoteCurrencies = canSendCurrencies.find(
    (item) => item.currency === baseCurrency
  )?.canSend ?? [];

  if (!walletId) {
    return <div>No Wallet Found. Please contact support.</div>;
  }

  return (
    <PageTransition>
      <div className="h-full max-w-xl mx-auto">
        <div className="p-8">
          <div className="relative">
            <GoArrowLeft
              size={24}
              className="absolute cursor-pointer top-1/2 -translate-y-1/2"
              onClick={() => history.go(-1)}
            />
            <h1 className="font-bold text-xl text-blue-600 text-center">
              Withdraw Funds
            </h1>
          </div>

          <Spacer size="md" />

          {/* Tab Switcher */}
          <div className="flex rounded-full bg-gray-200 p-1 mb-6">
            <button
              onClick={() => handleWithdrawalTypeChange(WithdrawalType.INTERNATIONAL)}
              className={`flex-1 py-3 px-6 rounded-full text-sm font-medium ${withdrawalType === WithdrawalType.INTERNATIONAL
                ? "bg-white text-green-600 shadow"
                : "text-gray-500"
                }`}
            >
              International
            </button>
            <button
              onClick={() => handleWithdrawalTypeChange(WithdrawalType.LOCAL)}
              className={`flex-1 py-3 px-6 rounded-full text-sm font-medium ${withdrawalType === WithdrawalType.LOCAL
                ? "bg-white text-green-600 shadow"
                : "text-gray-500"
                }`}
            >
              Local Transfer
            </button>
          </div>

          <WithdrawalForm
            isInternational={withdrawalType === WithdrawalType.INTERNATIONAL}
            baseAmount={baseAmount}
            quoteAmount={quoteAmount}
            baseCurrency={baseCurrency}
            quoteCurrency={quoteCurrency}
            isLoadingRates={isLoadingRates}
            exchangeRate={exchangeRate?.finalRate}
            balance={balance}
            isLoadingFee={isLoadingFee}
            withdrawalFee={withdrawalFee}
            baseCurrencies={baseCurrencies}
            quoteCurrencies={quoteCurrencies}
            remarks={remarks}
            onBaseAmountChange={handleBaseAmountChange}
            onQuoteAmountChange={handleQuoteAmountChange}
            onBaseCurrencyChange={handleBaseCurrencyChange}
            onQuoteCurrencyChange={handleQuoteCurrencyChange}
            onRemarksChange={handleRemarksChange}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </PageTransition>
  );
}
