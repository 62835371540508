import { useState } from "react";
import { createFileRoute, Link } from "@tanstack/react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useAppState,
  SAVE_USER,
  SAVE_AUTH_TOKEN,
} from "../../contexts/AppContext";
import { showToast } from "../../utils/toast";
import { apiClient } from "../../services/api";
import { getAuthErrorMessage } from "../../constants";
import { auth, createUserWithEmailAndPassword } from "../../utils/firebase";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import { useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";

export const Route = createFileRoute("/_auth/signup")({
  component: Signup,
});

// Validation schema using Yup
const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required").trim(),
  lastName: Yup.string().required("Last Name is required").trim(),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email Address is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]+$/, "Phone number must contain only digits"),
  referralCode: Yup.string(),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must have at least one uppercase letter")
    .matches(/[a-z]/, "Password must have at least one lowercase letter")
    .matches(/\d/, "Password must have at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must have at least one special character",
    ),
  termsAccepted: Yup.bool()
    .oneOf([true], "You must accept the terms and conditions")
    .required("You must accept the terms."),
});

function Signup() {
  const { dispatch } = useAppState();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      referralCode: "",
      password: "",
      termsAccepted: false,
    },
    validationSchema: SignupSchema,

    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const {
          firstName,
          lastName,
          email,
          phoneNumber,
          referralCode,
          password,
        } = values;

        // Capitalize first and last name
        const capitalizedFirstName =
          firstName.charAt(0).toUpperCase() + firstName.slice(1);
        const capitalizedLastName =
          lastName.charAt(0).toUpperCase() + lastName.slice(1);

        const { user: firebaseUser } = await createUserWithEmailAndPassword(
          auth,
          email,
          password,
        );
        const token = await firebaseUser.getIdToken();

        const userData = {
          id: firebaseUser.uid,
          email,
          password,
          firstName: capitalizedFirstName,
          lastName: capitalizedLastName,
          phoneNumber,
          referralCode,
          isNewUser: true,
          hasVerifiedID: false,
        };

        await apiClient.post({
          url: "users",
          body: userData,
        });

        dispatch({
          type: SAVE_USER,
          payload: userData,
        });
        localStorage.setItem("user", JSON.stringify(userData));

        dispatch({ type: SAVE_AUTH_TOKEN, payload: token });
        localStorage.setItem("token", token);

        navigate({ to: "/dashboard" });
      } catch (error) {
        const axiosError = error as AxiosError;
        console.error(
          "Signup error:",
          axiosError.response ? axiosError.response.data : axiosError,
        );
        const message = getAuthErrorMessage(axiosError);
        showToast(message, "error");
      } finally {
        setIsLoading(false);
      }
    },
  });

  // Function to validate password criteria dynamically
  const validatePassword = (password: string) => {
    setPasswordCriteria({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
  };

  return (
    <div className="flex flex-col">
      <h4 className="text-xl font-bold mb-10">
        Become a Global Citizen in seconds
      </h4>
      <form onSubmit={formik.handleSubmit}>
        {[
          { name: "firstName", label: "Legal First Name" },
          { name: "lastName", label: "Legal Last Name" },
          { name: "email", label: "Email" },
          { name: "phoneNumber", label: "Phone Number (WhatsApp)" },
          { name: "referralCode", label: "Referral Code (optional)" },
        ].map(({ name, label }) => (
          <div className="form-control mb-5" key={name}>
            <label className="block w-100 uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              {label}
            </label>
            <TextInput
              placeholder={`Enter your ${label.toLowerCase()}`}
              className="w-full py-4 px-2 rounded-lg border border-gray-200"
              {...formik.getFieldProps(name)}
              error={
                formik.touched[name as keyof typeof formik.touched] &&
                formik.errors[name as keyof typeof formik.errors]
                  ? {
                      message:
                        formik.errors[name as keyof typeof formik.errors],
                    }
                  : undefined
              }
            />
          </div>
        ))}

        <div className="form-control mb-5">
          <label className="block w-100 uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Password
          </label>
          <TextInput
            type="password"
            placeholder="Enter your password"
            className="w-full py-4 px-2 rounded-lg border border-gray-200"
            {...formik.getFieldProps("password")}
            onChange={(e) => {
              formik.handleChange(e);
              validatePassword(e.target.value);
            }}
            error={
              formik.touched.password && formik.errors.password
                ? { message: formik.errors.password }
                : undefined
            }
          />
        </div>

        <div className="form-control mb-5">
          <ul className="text-sm">
            <li className={passwordCriteria.length ? "text-green-500" : ""}>
              - At least 8 characters
            </li>
            <li className={passwordCriteria.uppercase ? "text-green-500" : ""}>
              - At least one uppercase character (A-Z)
            </li>
            <li className={passwordCriteria.lowercase ? "text-green-500" : ""}>
              - At least one lowercase character (a-z)
            </li>
            <li className={passwordCriteria.number ? "text-green-500" : ""}>
              - At least one number (0-9)
            </li>
            <li
              className={passwordCriteria.specialChar ? "text-green-500" : ""}
            >
              - At least one special character (!@#$%^&*)
            </li>
          </ul>
        </div>

        <div className="form-control mb-5">
          <label className="flex items-center">
            <input
              type="checkbox"
              {...formik.getFieldProps("termsAccepted")}
              className="mr-2"
            />
            <span>
              By creating an account, you agree to our{" "}
              <a
                href="https://passportmonie.notion.site/Terms-and-Conditions-a2a4f5f38e424baba70f31345b8cda39"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 underline"
              >
                Terms
              </a>{" "}
              and{" "}
              <a
                href="https://passportmonie.notion.site/Privacy-Policy-56f4825897154b4285ab5abd3fd02db6"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 underline"
              >
                Privacy Policy
              </a>
            </span>
          </label>
          {formik.touched.termsAccepted && formik.errors.termsAccepted && (
            <span className="text-red-500 text-xs">
              {formik.errors.termsAccepted}
            </span>
          )}
        </div>

        <Button
          className="bg-primary hover:bg-accent transition-colors font-bold text-white w-full py-3 mt-8 hover:border-accent rounded-lg border-primary"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Sign Up"}
        </Button>

        <div className="flex mt-4">
          <Link
            className="text-blue-600 hover:text-blue-800 underline text-sm cursor-pointer ml-auto"
            to="/login"
          >
            Already have an account? Log In
          </Link>
        </div>
      </form>
    </div>
  );
}

export default Signup;
