import { Link } from "@tanstack/react-router";
import Spacer from "../../Spacer";
import { StatusPill } from "../StatusPill";
import { additionalClass, TransactionStatus } from "../../../utils/transaction";
import { Transaction } from "../../../services/queries/transactions/types";

interface TransactionItemProps {
  isCurrencyExchange: boolean;
  transactionTitle: string;
  isDebit: boolean;
  transaction: Transaction;
}

export function TransactionItem({
  transaction,
  transactionTitle,
  isDebit,
  isCurrencyExchange,
}: TransactionItemProps) {
  const {
    beneficiary,
    amount,
    currency,
  } = transaction;

  const getInitials = beneficiary.accountName
    .split(" ")
    .map((name) => name[0])
    .join("")
    .toUpperCase();

  return (
    <Link
      to={`/transactions/${transaction.id}`}
      key={transaction.id}
    >
      <div className="border-b pb-3 mb-3 flex w-full gap-3 justify-start">
        <div
          className={`h-10 w-10 flex rounded-full justify-center items-center font-bold ${additionalClass[status as TransactionStatus]}`}
        >
          <span>{getInitials}</span>
        </div>
        <div className="grow">
          <div className="flex justify-between">
            <p className="text-sm">{transactionTitle}</p>
            <p className="font-semibold text-sm">
              {isDebit ? "-" : "+"}
              {amount.toLocaleString("en-US")} {currency}
            </p>
          </div>
          <Spacer />
          <div className="flex justify-between">
            <StatusPill status={transaction.status as TransactionStatus} />
            {isCurrencyExchange && (
              <p className="text-xs text-gray-500">
                {isDebit ? "-" : "+"}
                {/* {quoteAmount?.toLocaleString("en-US")} {quoteCurrency} */}
              </p>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}
