import { useState } from "react";
import { ClockIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";

import {
  getWebToken,
  configureSmileIdentityWebIntegration,
} from "../../../services/queries/identity";
import Button from "../../Button";

export function KYCVerification() {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleVerifyIdentity = async () => {
    setLoading(true);
    try {
      const product = "biometric_kyc";
      const tokenData = await getWebToken(product);
      if (!tokenData) {
        throw new Error("Error getting web token");
      }

      configureSmileIdentityWebIntegration(tokenData, product, {
        onSuccess: () => {
          setShowModal(true);
          setLoading(false);
        },
        onClose: () => {
          setLoading(false);
        },
        onError: () => {
          console.error("Error Verifying Identity:");
          alert("Failed to verify identity. Please try again.");
        },
      });
    } catch (error) {
      console.error({ error });

      setLoading(false);
    }
  };

  const handleContinue = () => {
    setShowModal(false);
  };

  return (
    <>
      <Button
        onClick={handleVerifyIdentity}
        disabled={loading}
        className="flex w-full bg-red-400 border-red-400 text-white px-4 rounded-md mb-4 flex items-center"
      >
        <ExclamationCircleIcon className="w-10 h-10 mr-2" />
        <p className="text-start">
          {loading
            ? "Verifying Identity..."
            : "Click here to complete your KYC verification."}
        </p>
      </Button>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 ">
          <div className="bg-white rounded-lg p-6 text-center shadow-lg m-4">
            <ClockIcon className="h-12 w-12 text-yellow-500 mx-auto mb-4" />

            <h2 className="text-lg font-semibold mb-4">
              Identity Verification Pending
            </h2>

            <p className="text-gray-600  mb-8">
              We are reviewing your Identity and would notify you once complete
            </p>
          <div className="flex justify-center ">
            <Button
              onClick={handleContinue}
              className="mt-4 px-6 py-3  bg-blue-500 text-white rounded-lg "
            >
              Continue
            </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
