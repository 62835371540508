import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import "@smileid/web-components/smart-camera-web";
import { ClockIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "@tanstack/react-router";

// interface CountryOption {
//   idTypes: { type: string; requiresDocument: boolean }[];
// }

// interface CountryOptions {
//   [key: string]: CountryOption;
// }

// const countryOptions: CountryOptions = {
//   Nigeria: {
//     idTypes: [
//       { type: "BVN", requiresDocument: false },
//       { type: "NIN", requiresDocument: false },
//     ],
//   },
//   // Kenya: {
//   //   idTypes: [
//   //     { type: "Driver License", requiresDocument: true },
//   //     { type: "Alien Card", requiresDocument: true },
//   //     { type: "National ID", requiresDocument: true },
//   //     { type: "International Passport", requiresDocument: true },
//   //   ],
//   // },
//   // Ghana: {
//   //   idTypes: [
//   //     { type: "Driver License", requiresDocument: true },
//   //     { type: "International Passport", requiresDocument: true },
//   //     { type: "Voter ID", requiresDocument: false },
//   //     { type: "New Voter ID", requiresDocument: false },
//   //     { type: "SSNIT", requiresDocument: false },
//   //   ],
//   // },
// };

export const Route = createFileRoute("/_app/kyc/")({
  component: KYCPage,
});

export default function KYCPage() {
  const navigate = useNavigate();
  // const [selectedCountry, setSelectedCountry] = useState<string>("");
  // const [selectedIdType, setSelectedIdType] = useState<string>("");
  // const [idNumber, setIdNumber] = useState<string>("");
  // const [showCountryDropdown, setShowCountryDropdown] =
  //   useState<boolean>(false);
  // const [showIdTypeDropdown, setShowIdTypeDropdown] = useState<boolean>(false);
  // const [captureType, setCaptureType] = useState<"selfie" | "document" | null>(
  //   null,
  // );
  // const [flowState, setFlowState] = useState<
  //   "selfieOnly" | "documentThenSelfie" | null
  // >(null);
  const [showModal, setShowModal] = useState(false);

  // useEffect(() => {
  //   const handlePublishEvent = (event: Event) => {
  //     const customEvent = event as CustomEvent; // Explicit cast to CustomEvent
  //     console.log("Data Captured:", customEvent.detail);

  //     if (captureType === "document" && flowState === "documentThenSelfie") {
  //       setCaptureType("selfie");
  //     } else if (captureType === "selfie") {
  //       setShowModal(true);
  //       disableCamera();
  //       (async () => {
  //         try {
  //           console.log("Fetching token for identity verification...");
  //           const { token } = await getWebToken(baseAPIURL, "biometric_kyc");
  //           console.log("Fetched Token:", token);
  //           configureSmileIdentityWebIntegration(token);


  //           // You can pass the token to Smile Identity components or use it as needed.
  //         } catch (error) {
  //           console.error("Error fetching token:", error);
  //           console.error("Error initializing Smile Identity:", error);
  //           alert("Failed to initialize verification.");
  //           alert("Failed to verify identity. Please try again.");
  //         } finally {
  //         // Clean up the camera component.
  //         }
  //       })();
  //     }
  //   };

  //   const captureComponent =
  //     captureType === "selfie"
  //       ? document.querySelector("selfie-capture-screens")
  //       : document.querySelector("document-capture-screens");

  //   if (captureComponent) {
  //     captureComponent.addEventListener(
  //       `${captureType}-capture-screens.publish`,
  //       handlePublishEvent,
  //     );
  //   }

  //   return () => {
  //     captureComponent?.removeEventListener(
  //       `${captureType}-capture-screens.publish`,
  //       handlePublishEvent,
  //     );
  //   };
  // }, [captureType, flowState]);

  // const handleCountrySelect = (country: string): void => {
  //   setSelectedCountry(country);
  //   setSelectedIdType("");
  //   setShowCountryDropdown(false);
  // };

  // const handleIdTypeSelect = (type: string): void => {
  //   setSelectedIdType(type);
  //   setShowIdTypeDropdown(false);
  // };

  // const handleContinue = () => {
  //   const selectedIdTypeObj = countryOptions[selectedCountry].idTypes.find(
  //     (idTypeObj) => idTypeObj.type === selectedIdType,
  //   );

  //   if (selectedIdTypeObj) {
  //     const requiresDocument = selectedIdTypeObj.requiresDocument;
  //     setFlowState(requiresDocument ? "documentThenSelfie" : "selfieOnly");
  //     setCaptureType(requiresDocument ? "document" : "selfie");
  //   }
  // };

  // const disableCamera = () => {
  //   const captureComponent =
  //     captureType === "selfie"
  //       ? document.querySelector("selfie-capture-screens")
  //       : document.querySelector("document-capture-screens");

  //   captureComponent?.remove();
  // };



  return (
    <div className="flex flex-col h-screen bg-gray-50">
      {/* {!captureType && ( */}
      <>
        {/* KYC Form */}
        <div className="p-4 flex items-center">
          <h1 className="text-xl font-medium text-blue-500">
            Complete Your KYC
          </h1>
        </div>

        {/* <div className="p-4 flex flex-col space-y-4">
            <div className="relative">
              <button
                onClick={() => setShowCountryDropdown(!showCountryDropdown)}
                className="w-full p-3 text-left bg-white rounded-lg border border-gray-200"
              >
                {selectedCountry || "Select Country"}
                <ChevronDownIcon className="h-5 w-5 text-gray-500 absolute right-3 top-1/2 transform -translate-y-1/2" />
              </button>
              {showCountryDropdown && (
                <div className="absolute w-full bg-white rounded-lg shadow-lg z-10">
                  {Object.keys(countryOptions).map((country) => (
                    <button
                      key={country}
                      onClick={() => handleCountrySelect(country)}
                      className="w-full p-3 text-left hover:bg-gray-100"
                    >
                      {country}
                    </button>
                  ))}
                </div>
              )}
            </div>

            <div className="relative">
              <button
                onClick={() => setShowIdTypeDropdown(!showIdTypeDropdown)}
                className="w-full p-3 text-left bg-white rounded-lg border border-gray-200"
              >
                {selectedIdType || "Select ID Type"}
                <ChevronDownIcon className="h-5 w-5 text-gray-500 absolute right-3 top-1/2 transform -translate-y-1/2" />
              </button>
              {showIdTypeDropdown && (
                <div className="absolute w-full bg-white rounded-lg shadow-lg z-10">
                  {selectedCountry ? (
                    countryOptions[selectedCountry].idTypes.map((typeObj) => (
                      <button
                        key={typeObj.type}
                        onClick={() => handleIdTypeSelect(typeObj.type)}
                        className="w-full p-3 text-left hover:bg-gray-100"
                      >
                        {typeObj.type}
                      </button>
                    ))
                  ) : (
                    <div className="p-3 text-gray-500">
                      Please select a country first
                    </div>
                  )}
                </div>
              )}
            </div>

            <input
              type="text"
              value={idNumber}
              onChange={(e) => setIdNumber(e.target.value)}
              placeholder="Enter ID Number"
              className="w-full p-3 bg-white rounded-lg border border-gray-200"
            />

            <button
              disabled={!selectedCountry || !selectedIdType || !idNumber}
              onClick={handleContinue}
              className="w-full p-3 rounded-lg bg-blue-500 text-white"
            >
              Continue
            </button>
          </div> */}
      </>
      {/* )} */}

      {/* Render the appropriate capture component */}
      {/* {captureType && (
        <div className="p-4 h-full overflow-y-auto bg-white rounded-lg">
          {captureType === "selfie" &&
            React.createElement("selfie-capture-screens")}
          {captureType === "document" &&
            React.createElement("document-capture-screens")}
        </div>
      )} */}

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white rounded-lg p-6 text-center shadow-lg">
            <ClockIcon className="h-12 w-12 text-yellow-500 mx-auto" />
            <h2 className="text-lg font-semibold mt-4">
              Identity Verification Pending
            </h2>
            <p className="text-gray-600 mt-2">
              We are reviewing your document and would notify you once complete
            </p>
            {/* <button
              onClick={handleModalContinue}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
            >
              Continue
            </button> */}
          </div>
        </div>
      )}
    </div>
  );
}