import { createFileRoute } from "@tanstack/react-router";
import ComingSoon from "../../../components/ComingSoon";

export const Route = createFileRoute("/_app/cards/")({
  component: CardsPage,
});

function CardsPage() {
  return (
    <ComingSoon 
      title="Cards Coming Soon!"
      description="We're working on bringing you a seamless card management experience. Check back soon!"
    />
  );
}