import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      when: "beforeChildren",
    },
  },
  exit: { 
    opacity: 0,
    transition: {
      when: "afterChildren",
    }
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  show: { 
    opacity: 1, 
    y: 0,
    transition: {
      type: "spring",
      stiffness: 200,
      damping: 25,
    }
  },
};

const AppSkeleton = () => {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="show"
      exit="exit"
      className="min-h-screen bg-appbg md:hidden will-change-transform"
    >
      {/* Header Skeleton */}
      <motion.div variants={itemVariants} className="p-4">
        <div className="h-7 w-40 bg-gray-200 rounded-md animate-pulse mb-2" />
      </motion.div>

      {/* Wallet Card Skeleton */}
      <motion.div variants={itemVariants} className="px-4 mb-6">
        <div className="bg-white p-4 rounded-xl shadow-sm">
          <div className="flex justify-between items-center mb-4">
            <div className="h-5 w-24 bg-gray-200 rounded animate-pulse" />
            <div className="h-5 w-16 bg-gray-200 rounded animate-pulse" />
          </div>
          <div className="h-8 w-40 bg-gray-200 rounded animate-pulse mb-4" />
          <div className="flex justify-between">
            <div className="h-4 w-20 bg-gray-200 rounded animate-pulse" />
            <div className="h-4 w-20 bg-gray-200 rounded animate-pulse" />
          </div>
        </div>
      </motion.div>

      {/* Recent Transactions Section */}
      <motion.div variants={itemVariants} className="px-4">
        <div className="flex justify-between items-center mb-4">
          <div className="h-5 w-36 bg-gray-200 rounded animate-pulse" />
          <div className="h-5 w-16 bg-gray-200 rounded animate-pulse" />
        </div>
        
        {/* Transaction List */}
        <div className="space-y-3">
          {[1, 2, 3, 4].map((i) => (
            <div key={i} className="bg-white p-3 rounded-xl shadow-sm">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-10 w-10 bg-gray-200 rounded-full animate-pulse" />
                  <div className="space-y-2">
                    <div className="h-4 w-24 bg-gray-200 rounded animate-pulse" />
                    <div className="h-3 w-16 bg-gray-200 rounded animate-pulse" />
                  </div>
                </div>
                <div className="h-5 w-20 bg-gray-200 rounded animate-pulse" />
              </div>
            </div>
          ))}
        </div>
      </motion.div>

      {/* Bottom Navigation */}
      <motion.div
        variants={itemVariants}
        className="fixed bottom-0 left-0 right-0 h-16 bg-white border-t px-6"
      >
        <div className="flex justify-between items-center h-full">
          {[1, 2, 3, 4].map((i) => (
            <div key={i} className="flex flex-col items-center space-y-1">
              <div className="h-6 w-6 bg-gray-200 rounded animate-pulse" />
              <div className="h-3 w-12 bg-gray-200 rounded animate-pulse" />
            </div>
          ))}
        </div>
      </motion.div>

      {/* Bottom Padding */}
      <div className="h-20" />
    </motion.div>
  );
};

export default AppSkeleton; 