import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";

import { apiClient } from "../../api";
import keys from "./keys";
import { GET_TRANSFER_CONFIG_API_RESPONSE, GET_WALLETS_API_RESPONSE, WithdrawalParams, WithdrawalRequest, WithdrawalResponse } from "./types";
import { baseUrl, currencyDetails } from "../../../constants";

interface GetWalletsReturnType {
  isLoading: boolean;
  data: GET_WALLETS_API_RESPONSE | null;
  isSuccess: boolean;
  error: unknown;
}

interface GetTransferConfigReturnType {
  isLoading: boolean;
  data: GET_TRANSFER_CONFIG_API_RESPONSE | null;
  isSuccess: boolean;
  error: unknown;
}

export const walletsResourcePath = "wallets/user";
export const transferConfigResourcePath = "payments/transfer/config";

const useGetWallets = (
  url: string,
): GetWalletsReturnType => {
  const hash = [keys.read, `${url}`];

  const {
    isLoading,
    data = null,
    isSuccess,
    error,
  }: UseQueryResult<GET_WALLETS_API_RESPONSE | null, unknown> = useQuery(
    hash,
    async () => await apiClient.get({ url }),
  );
  return { isLoading, data, isSuccess, error };
};

const useGetTransferConfig = (amount: string, currency: string, currencyPaymentMethod?: string): GetTransferConfigReturnType => {
  let paymentMethod = currencyPaymentMethod;
  if (!paymentMethod) {
    // get default payment method for currency
    paymentMethod = currencyDetails.find(detail => detail.currency === currency)?.paymentMethods[0].value;
  }

  const url = Number(amount) && currency ? `${baseUrl}/${transferConfigResourcePath}?amount=${amount}&currency=${currency}&paymentMethod=${paymentMethod}` : '';
  const hash = [keys.read, url];

  const {
    isInitialLoading: isLoading,
    data = null,
    isSuccess,
    error,
  }: UseQueryResult<GET_TRANSFER_CONFIG_API_RESPONSE | null, unknown> = useQuery(
    hash,
    async () => await apiClient.get({ url }),
    {
      enabled: !!url,
    }
  );
  return { isLoading, data, isSuccess, error };
};


const useCreateWithdrawal = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ data: body, walletId }: WithdrawalParams): Promise<WithdrawalResponse> => {
      const response = await apiClient.post({
        url: `${baseUrl}/wallets/${walletId}/withdraw`,
        body,
      });
      return response.data;
    },
    onSuccess: () => {
      // Invalidate the wallets query to refresh balances
      queryClient.invalidateQueries({
        queryKey: [keys.read],
      });
    },
  });
};


export { useGetWallets, useGetTransferConfig, useCreateWithdrawal };