import {
  useQuery,
  type UseQueryResult,
} from "@tanstack/react-query";

import { apiClient } from "../../api";
import {
  type GET_TRANSACTIONS_API_RESPONSE,
  type GET_TRANSACTION_API_RESPONSE,
} from "./types";
import keys from "./keys";
import { baseURL } from "../../../utils";

export const transactionsResourcePath = "transactions/user";

interface GetTransactionsReturnType {
  isLoading: boolean;
  data: GET_TRANSACTIONS_API_RESPONSE | undefined;
  isSuccess: boolean;
  error: unknown;
}

interface GetTransactionReturnType {
  isLoading: boolean;
  data: GET_TRANSACTION_API_RESPONSE | undefined;
  isSuccess: boolean;
  error: unknown;
}


const useGetTransactions = (
  url: string,
  pageNumber: string = "1",
): GetTransactionsReturnType => {
  const hash = [keys.read, `${pageNumber}`];
  const {
    isLoading,
    data,
    isSuccess,
    error,
  }: UseQueryResult<GET_TRANSACTIONS_API_RESPONSE, unknown> = useQuery(
    hash,
    async () => await apiClient.get({ url }),
  );
  return { isLoading, data, isSuccess, error };
};

const useGetTransaction = (id: string): GetTransactionReturnType => {
  const hash = [keys.read, `${id}`];
  const {
    isLoading,
    data,
    isSuccess,
    error,
  }: UseQueryResult<GET_TRANSACTION_API_RESPONSE, unknown> = useQuery(
    hash,
    async () =>
      await apiClient.get({
        url: `${baseURL}/${transactionsResourcePath}/${id}`,
      }),
  );
  return { isLoading, data, isSuccess, error };
};

export {
  useGetTransactions,
  useGetTransaction,
};
