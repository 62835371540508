import { createFileRoute } from "@tanstack/react-router";

import { Wallet } from "../../../components/wallets/Wallet";
import { useAppState } from "../../../contexts/AppContext";
import { DashboardHeaderWidget } from "../../../components/dashboard/DashboardHeaderWidget";
import { Currency } from "../../../constants";
import PageTransition from "../../../components/PageTransition";
import { KYCVerification } from "../../../components/identity/KYCVerification";

export const Route = createFileRoute("/_app/dashboard/")({
  component: DashboardPage,
});

function DashboardPage() {
  const {
    state: { user },
  } = useAppState();
  const isVerifiedUser = user?.hasVerifiedID ?? false;

  return (
    <PageTransition>
      <div className="flex flex-col h-screen">
        <div className="dashboard-header-mobile w-full bg-primary rounded-b-3xl px-6 pt-8 text-white">
          <DashboardHeaderWidget
            firstName={user?.firstName}
            lastName={user?.lastName}
          />

          <div className="wallet-container mb-[-50px]">
            <Wallet currency={Currency.NGN} isVerifiedUser={isVerifiedUser} />
          </div>
        </div>

        <div className="flex-1 px-6 py-20">
          {!isVerifiedUser && (
            <KYCVerification />
          )}
        </div>
      </div>
    </PageTransition>
  );
}
