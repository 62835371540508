import { createFileRoute } from "@tanstack/react-router";
import PageTransition from "../../../components/PageTransition";
import Spacer from "../../../components/Spacer";
import { TransactionItem } from "../../../components/transactions/TransactionItem";
import {
  transactionsResourcePath,
  useGetTransactions,
} from "../../../services/queries/transactions";
import useFilters from "../../../hooks/useFilters";
import { formatDate } from "../../../utils/transaction";
import { Transaction } from "../../../services/queries/transactions/types";
import { useAppState } from "../../../contexts/AppContext";

export const Route = createFileRoute("/_app/transactions/")({
  component: TransactionsPage,
});

function TransactionsPage() {
  const { url } = useFilters(transactionsResourcePath);
  const { data: transactionsData, isLoading } = useGetTransactions(url.href);

  if (isLoading) {
    return (
      <div className="p-6 max-w-lg mx-auto w-full">
        <div className="bg-white rounded-2xl p-6 space-y-6">
          <div className="animate-pulse space-y-6">
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
          </div>
        </div>
        <Spacer size="lg" />
        <div className="bg-white rounded-2xl p-6 space-y-6">
          <div className="animate-pulse space-y-6">
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
          </div>
        </div>
        <Spacer size="lg" />
        <div className="bg-white rounded-2xl p-6 space-y-6">
          <div className="animate-pulse space-y-6">
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
          </div>
        </div>
      </div>
    );
  }

  const transactions = transactionsData?.data.list as Transaction[];

  if (!transactions?.length) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-gray-500 text-xl">No transactions found.</p>
      </div>
    );
  }

  const transactionsByDate: Record<string, Transaction[]> =
    transactions?.reduce(
      (acc, transaction) => {
        console.log({ transaction });
        const dateCreated = transaction.created.split("T")[0];

        if (!acc[dateCreated]) {
          acc[dateCreated] = [];
        }
        acc[dateCreated].push(transaction);
        return acc;
      },
      {} as Record<string, Transaction[]>,
    );

  return (
    <PageTransition>
      <div className="transactions-page p-8 mb-24">
        <p className="text-center font-bold text-xl text-blue-600">
          Transaction History
        </p>
        <Spacer size="lg" />
        {Object.entries(transactionsByDate).map(([dateCreated, transactions]) => (
          <div key={dateCreated}>
            <Spacer size="lg" />
            <p className="text-gray-500 text-sm">{formatDate(dateCreated)}</p>
            <Spacer size="md" />
            {transactions?.map((transaction) => (
              <TransactionItem
                key={transaction.id}
                transactionTitle="Transfer"
                transaction={transaction}
                isDebit
                isCurrencyExchange
              />
            ))}
          </div>
        ))}
      </div>
    </PageTransition>
  );
}
