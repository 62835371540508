import { createFileRoute, useSearch } from "@tanstack/react-router";
import CopyButton from "../../../components/CopyButton";
import { useGetVirtualAccounts, virtualAccountsResourcePath } from "../../../services/queries/virtual-accounts";
import useFilters from "../../../hooks/useFilters";
import PageTransition from "../../../components/PageTransition";
import { GoArrowLeft } from "react-icons/go";

interface DepositSearchParams {
  currency?: string;
}

function AccountDetail({ label, value }: { label: string; value: string }) {
  return (
    <div className="flex flex-col space-y-1">
      <span className="text-sm text-gray-500">{label}</span>
      <div className="flex items-center justify-between">
        <span className="text-lg font-medium">{value}</span>
        <CopyButton textToCopy={value} />
      </div>
    </div>
  );
}

export const Route = createFileRoute("/_app/deposit/")({
  validateSearch: (search: Record<string, unknown>): DepositSearchParams => {
    return {
      currency: search.currency as string,
    };
  },
  component: DepositPage,
});

function DepositPage() {
  const { currency = "NGN" } = useSearch({ from: Route.id }) as DepositSearchParams;

  const { url } = useFilters(virtualAccountsResourcePath, { pageSize: "1", currency });
  const { data: accountData, isLoading } = useGetVirtualAccounts(url.href);

  if (isLoading) {
    return (
      <div className="p-6 max-w-lg mx-auto w-full">
        <div className="bg-white rounded-2xl p-6 space-y-6">
          <div className="animate-pulse space-y-6">
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            <div className="h-8 bg-gray-200 rounded w-3/4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            <div className="h-8 bg-gray-200 rounded w-3/4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            <div className="h-8 bg-gray-200 rounded w-3/4"></div>
          </div>
        </div>
      </div>
    );
  }

  const account = accountData?.data?.list[0];

  if (!account) {
    return (
      <div className="p-6 max-w-lg mx-auto w-full">
        <h1 className="text-2xl font-bold mb-2">Deposit Funds</h1>
        <div className="bg-white rounded-2xl p-6">
          <p className="text-center text-gray-500">No virtual account found for {currency}</p>
        </div>
      </div>
    );
  }

  return (
    <PageTransition>
      <div className="p-6 max-w-lg mx-auto w-full">
        <div className="bg-white rounded-2xl p-6">
          <div className="space-y-6">
            <div className="relative">
              <GoArrowLeft
                size={24}
                className="absolute cursor-pointer top-1/2 -translate-y-1/2"
                onClick={() => history.go(-1)}
              />
              <h1 className="font-bold text-xl text-blue-600 text-center">
                Deposit Funds
              </h1>
            </div>

            <div>
              <p className="text-gray-500">
                Transfer to this account to fund your wallet instantly
              </p>
            </div>

            <div className="space-y-6">
              <AccountDetail
                label="Bank Name"
                value={account?.bankName}
              />
              <AccountDetail
                label="Account Number"
                value={account?.accountNumber}
              />
              <AccountDetail
                label="Account Name"
                value={account?.accountName}
              />
            </div>

            <div className="bg-blue-50 p-4 rounded-lg">
              <p className="text-sm text-blue-800">
                Transfers to this account are processed instantly. The account is unique to you and can be used multiple times.
              </p>
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  );
} 